import getNami from './getNami.js'

const ensureNami = async (state)=> {
    

    if (window.cardano) {
        state.data.nami.isInstalled = true

        
        const wallet = window.cardano[state.data.nami.type]
        if (wallet == null) {
          state.data.nami.chooseWallet = true
          const obj = document.getElementById("web3-status-connected")
          if (obj) {
            obj.click()
          }
          return
        }

        state.data.nami.chooseWallet = false
        state.data.nami.isEnabled = await wallet.isEnabled()
        if (state.data.nami.isEnabled == true ) {
          const nami = await getNami(state)
          state.data.nami.account = await nami.getAddress()
          return
        }
      }
      else {
        state.data.nami.isInstalled = false
      }

}

export default ensureNami;