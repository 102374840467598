import React from 'react'
import styled from 'styled-components/macro'

//import Header from './Header.tsx'
import SwitchTabs from './SwitchTabs.tsx'
import NetworkBoxes from './NetworkBoxes.tsx'
import SendPlaceholder from './SendPlaceholder.tsx'
import ReceivePlaceholder from './ReceivePlaceholder.tsx'
import ExpandMoreButton from './ExpandMoreButton.tsx'
import Modals from './Modals.tsx'
import SwapForm from '../SwapForm'
import PoolForm from '../PoolForm'
import Loader from './Loader'
//import { useAlert } from "react-alert";
//import { display, textAlign } from 'styled-system'
//import User from '../../assets/svg/user.svg'
import CloudOff from '../../assets/svg/cloud-off.svg'
import { Message, MessageIcon, MessageText } from '../PoolForm/Message'
import ConnectWallet from './ConnectWallet.tsx'
import { switchNetwork } from './switchNetwork'
import { ButtonPrimary } from '../../components/Button'
import NetworkLabel from '../SwapForm/NetworkLabel'
import { NETWORK_LABELS, NETWORK_LOGOS } from '../../constants/chains'

const SwitchNetwork = (state: any)=> {

  const doSwitch = ()=> {
    switchNetwork(state, state.root.requestChainId, (err: any)=> {
      if (err) {
        return
      }
      state.root.requestChainId = null
      location.reload();
    })
  }

  const keepCurrent = ()=> {
    location.href = location.href.replace(/#.+/,'');
  }


  return (<div style={{ padding: "50px", textAlign: "center" }}>
      <div style={{ width: "320px", display: "inline-block" }}>
        <div style={{padding: "15px"}}>
        In order to swap from {NETWORK_LABELS[state.root.requestChainId]} to {NETWORK_LABELS[state.data.taker.chainId]} please Click
        </div>
        <ButtonPrimary onClick={doSwitch} >Switch to {NETWORK_LABELS[state.root.requestChainId]} Network</ButtonPrimary>
      </div>
      <div style={{padding: "15px"}}>or Click to</div>
      <div style={{ width: "320px", display: "inline-block" }}>
        <ButtonPrimary style={{background: "#1f2125"}} onClick={keepCurrent} >Continue with {NETWORK_LABELS[state.chainId]}  Network</ButtonPrimary>
      </div>
    </div>)
  

}

const App = styled.div`
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: calc(100vh - 80px);
  font-family: Poppins;
  text-align: center;
  background-size: cover;
  color: white;
  box-sizing: border-box;
`

const ChoseTaker = function(state: any, tokenContract: any, swapContract: any, alert: any) {
      
  if (state.account == null) {
    
    return (
      <>
      {NetworkBoxes(state, alert)}
      <ConnectWallet />
      {Modals(state, alert)}
      </>)
  }

  if (state.data.maker.coin == null)
    return (
      <>
      {NetworkBoxes(state, alert)}
      <Message>
            <MessageIcon src={CloudOff} alt={'Account'} />
            <MessageText><div>Please connect to a swapz supported network</div></MessageText>
      </Message>
      {Modals(state, alert)}
      </>)


  
  return (
    <>
        {NetworkBoxes(state, alert)}
        {SendPlaceholder(state, alert)}
        {ReceivePlaceholder(state, alert)}
        {ExpandMoreButton(state, alert)}
        {Modals(state, alert)}
        
    </>
    )
}

const AppBody = function(state: any, tokenContract: any, swapContract: any, alert: any) {

  if (state.root.requestChainId != null) {
    return SwitchNetwork(state);
  }

  if (state.root.page == "Pool") {
    return PoolForm(state, tokenContract, swapContract, alert);
  }

  if (state.data.taker.coin == null) {
    return ChoseTaker(state, tokenContract, swapContract, alert);
  }

  
  return SwapForm(state, tokenContract, swapContract, alert);
  
  
}

export default function Component(state: any, tokenContract: any, swapContract: any, alert: any) {
    return (
        <App>
          {SwitchTabs(state, tokenContract, swapContract)}
          {state.data.loading == true ? Loader(): AppBody(state, tokenContract, swapContract, alert)}
        </App>
    )
}