import React from 'react'
import styled from 'styled-components/macro'



const SendPlaceholderHeader = styled.div`
  position: relative;
`

export default function Component(allData) {
    return (
        <SendPlaceholderHeader></SendPlaceholderHeader>
    )
}