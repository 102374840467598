import React from 'react'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import InputPanel from '../../components/InputPanel'
import { BottomGrouping } from '../../components/swap/styleds'
import changeAmount from "./changeAmount.js"
import renderAllowButton from './renderAllowButton'
import renderSwapButton from './renderSwapButton'
import Chevron from 'react-chevron'
import Collapsed from './components/Collapsed'
import CollapsedNumber from './components/CollapsedNumber'
import NetworkLabel from './NetworkLabel'
import styled from 'styled-components'
import renderAmount from './renderAmount';
import getFee from './getFee'

const Value = styled.div`
  display: inline-block;
  line-height: 23px;
  vertical-align: top;
  padding: 2px;
  padding-left: 16px;
  width: 140px;
  text-align: left;
`

const NetworkWrapper = styled.div`
  margin-bottom: 3px;
`



const renderForm = (state, tokenContract, swapContract, alert)=> {

  const data = state.data;

  const changeRecipient = (value)=> {
    data.taker.recipient = value;
  }

  const useMax = ()=> {
    changeAmount(data, "maker")(data.taker.max)
  }


const risk = (function(){
  switch (false) {
  //case !(true):
  //  return "Currently all swaps are paused";
    //case !(+data.taker.amount > 2000):
  //  return "Maximum swap amount is 2000";
  case !(data.coins[data.maker.coin].type):
    return null 
  case !(+data.taker.amountEth < 0.3):
    return "The network balance is too small to send a transaction";
    
  case !(+data.taker.balancePool - +data.taker.amount < 5000):
    return `Liquidity is too low. Balance is ${renderAmount(data.taker.balancePool)} ${data.coins[data.taker.coin].text}`;
  default:
    return null;
  }
}());


if (data.process.stage == 0) {
  return (            
    <div style={{marginBottom: "10px"}}>
      <div style={{ display: 'relative'}}>
       <NetworkWrapper>
        <NetworkLabel chainId={data.coins[data.maker.coin].chainId} />
       </NetworkWrapper>
        <CurrencyInputPanel
          value={data.maker.amount}
          id="swap-currency-output"
          onUserInput={changeAmount(data, "maker")}
          currency={data.coins[data.maker.coin]}
        />
        <div style={{padding: '5px'}}>
          <span style={{paddingRight: '5px', color: 'gray'}}>Balance:</span>
          <span style={{color: 'white'}}>{renderAmount(data.maker.balance)} {data.coins[data.maker.coin].text}</span>
          <div style={{ color: 'gray' }}>
            <span style={{ color: 'gray', paddingRight: '5px' }} onClick={useMax}>Max:</span>
            <span style={{color: 'white'}}>{renderAmount(data.taker.max)} {data.coins[data.maker.coin].text}</span>                    
          </div>
        </div>
        <div style={{ height: '20px' }}>
        </div>
        <NetworkWrapper>
          <NetworkLabel chainId={data.coins[data.taker.coin].chainId} />
        </NetworkWrapper>
        <CurrencyInputPanel
          value={data.taker.amount}
          id="swap-currency-output"
          onUserInput={changeAmount(data, "taker")}
          currency={data.coins[data.taker.coin]}
        />
        { data.coins[data.maker.coin].recipientField ? (
          <>
            <div style={{ paddingLeft: '5px', opacity: "0.2", paddingTop: "5px", paddingBottom: "3px" }}>RECIPIENT</div>
            <InputPanel
              value={data.taker.recipient}
              placeholder={data.coins[data.maker.coin].recipientField}
              id="swap-recipient-output"
              onUserInput={changeRecipient}
            />
          </>
        ) : null }
          <div style={{padding: '5px'}}>
          <span>
            <div style={{ color: 'gray'}}>
              <span style={{paddingRight: '5px'}}>Balance:</span><span style={{color: 'white'}}>{renderAmount(data.taker.balance)} {data.coins[data.taker.coin].text}</span>
            </div>
            <div style={{ color: 'gray', marginTop: "30px"}}>
              <span style={{paddingRight: '5px'}}>LP Fee:</span><span style={{color: 'white'}}>{renderAmount(data.taker.fee.LP)} {data.coins[data.taker.coin].text}</span>
            </div>
            <div style={{ color: 'gray', marginTop: "5px"}}>
              <span style={{paddingRight: '5px'}}>Crosschain Fee:</span><span style={{color: 'white'}}>{renderAmount(data.maker.fee.Crosschain)} {data.coins[data.maker.coin].nativeCoin}</span>
            </div>
            { !(risk) ? null : (<div style={{ color: 'orange', marginTop: "10px", border: "1px solid orange", borderRadius: "5px", padding: "5px", background: "#ffa5001a"}}>
              <span style={{paddingRight: '5px', fontWeight: "bold"}}>Warning:</span><span>{risk}</span>
            </div>) }
          </span>
        </div>
      </div>
      <div style={{ height: '35px' }}>
      {(data.process.error)  ? (<div style={{color: 'red', padding: '5px'}}>{data.process.error}</div>) : null}
      </div>
      <BottomGrouping>
        {renderAllowButton(state, tokenContract, alert)}
        {renderSwapButton(state, swapContract, alert)}
      </BottomGrouping>
      </div>)
  }

  const maker_amount = data.process.maker_amount || data.maker.amount

  if (data.process.stage > 0) {
    return (
      <Collapsed style={{lineHeight: "30px", padding: "5px"}}>
        <CollapsedNumber>1</CollapsedNumber>
        <span>
            <div>
                <Value>{renderAmount(maker_amount)} {data.coins[data.maker.coin].text}</Value> 
                <NetworkLabel chainId={data.coins[data.maker.coin].chainId} />
            </div>
            <Chevron direction={'down'} />
            <div>
                <Value>{renderAmount(maker_amount - getFee(maker_amount, data))} {data.coins[data.taker.coin].text}</Value>
                <NetworkLabel chainId={data.coins[data.taker.coin].chainId} />
            </div>
        </span>
      </Collapsed>
    );
  }

}

export default renderForm;