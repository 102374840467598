import { makeHumanAmounts } from '../SwapInfo/makeHumanAmounts';

export const isAllowed = (data: any) => {
  
  if (data.maker.allowance == "true")
    return true;

  if (data.maker.allowance == "done")
    return true;

  if (data.maker.allowance == "false")
    return false;
  
  if (+data.maker.allowance <= 0)
    return false;
  
  const maker = data.coins[data.maker.coin];

  const allowedAmount = +makeHumanAmounts(data.maker.allowance, maker.decimals);
  const requestedAmount = +data.maker.amount;

  if (isNaN(allowedAmount) || isNaN(requestedAmount))
    return false;

  const approved = requestedAmount <= allowedAmount;

  //console.log("allowedAmount", allowedAmount, "requestedAmount", requestedAmount, approved)

  return approved;

};
