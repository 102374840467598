import React from 'react'
//import styled, { keyframes }  from 'styled-components'
import Select from 'react-select'
import { toJS } from 'mobx'
import { NETWORK_LABELS } from '../../constants/chains'
import refreshPoolInfo from '../PoolInfo/refreshPoolInfo'
import { makeHumanAmounts } from '../SwapInfo/makeHumanAmounts'
import { NETWORK_URLS } from '../../connectors'
import Web3 from 'web3'
import ERC20_ABI from '../../abis/erc20.json'
import POOL_ABI from '../../abis/pool.json'

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'gray' : 'white',
      background: state.isSelected ?  '#2c3038' : 'transparent',
      textAlign: "left"
    }),
    menu: (provided) => ({
        ...provided,
        background: "#2c3038",
        color: 'white',
        top: "-17px",
        left: "-7px",
        border: "0px",
        boxShadow: "0px",
        width: "155px",
        borderRadius: "0 30px 0 0",
        overflow: "hidden"
      }),
    container : (provided)=> ({
        ...provided,
        width: "150px"
      }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none"
      }),
    indicatorContainer : (provided) => ({
        ...provided,
        padding: "0"
        }),
    valueContainer : (provided) => ({
        ...provided,
        padding: "0"
    }),
    control: (provided) => ({
        ...provided,
        background: "transparent",
        borderColor: "transparent",
        border: "0px",
        outline: "0px",
        color: 'white',
        minHeight: "0px",
        boxShadow: "0px"
      }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = 'white'
      const top = "55%"
      const textAlign = "center"
      const width = "100%"
  
      return { ...provided, opacity, transition, color, top, textAlign, width };
    }
  }

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}


const chooseNetwork = (state: any)=> {

    const data = state.data;
    const options = toJS(data.coins).filter((x: any)=> x.group == data.coins[data.maker.coin].group).map((x)=> { return { value: x.chainId + "_" + x.assetId, label: NETWORK_LABELS[x.chainId] + " " + x.name }})
    
    const selectWithdrawNetwork = async (option: any)=> {
        const coin = data.coins.find((x: any)=> x.chainId + "_" + x.assetId == option.value && x.group == data.coins[data.maker.coin].group)
        
        data.taker.chainIdPool = coin.chainId
        data.taker.assetId = coin.assetId.toString()
        data.taker.coin = data.coins.indexOf(coin)

        //const takerWeb3 = new Web3(NETWORK_URLS[option.value])
        //const takerTokenContract = new takerWeb3.eth.Contract(ERC20_ABI, taker.tokenAddress)
        //const takerPoolContract = new takerWeb3.eth.Contract(POOL_ABI, taker.swapAddress)
        //const owner = await takerPoolContract.methods.owner().call();
        //const takerMeshBalance = await takerWeb3.eth.getBalance(owner)
        //const takerPoolBalance = await takerTokenContract.methods.balanceOf(taker.swapAddress).call();
        //data.taker.balanceEth = makeHumanAmounts(takerMeshBalance, "1000000000000000000")
        data.loadingButton = 'leave'
        console.log("choose network")
        await refreshPoolInfo(state, false)
        data.loadingButton = null
    }

    const option = options.find((x: any)=> x.value === data.taker.chainIdPool + '_' + data.taker.assetId )


    return (<Select options={options} styles={customStyles} onChange={selectWithdrawNetwork} defaultValue={option} value={option} />)
}

export default chooseNetwork;