import { Trans } from '@lingui/macro'
import React from 'react'
import { ButtonPrimary } from '../../components/Button'
import { MaxUint256 } from '@ethersproject/constants'
import refreshSwap from '../SwapInfo/refreshSwapInfo.js'
import { Loading } from '../PoolForm/Loading'
import { isAllowed } from '../PoolForm/isAllowed'



interface IIntrinsicAttributes {
  data: any
}

const renderAllowButton = (state, tokenContract) => {

  const data = state.data;

  if (data.coins[data.maker.coin].tokenAddress == "native")
    return

  const allow = () => {
    const coin = data.coins[data.maker.coin];
    if (tokenContract == null) {
      alert('Token Contract is null')
      return
    }
    data.loadingButton = "approve"
    const swapAddress = coin.swapAddress
    const value = +data.maker.amount > 0 ? ( Math.ceil(+data.maker.amount) + coin.decimals.replace("1", "") ) : MaxUint256
    tokenContract
      .approve(swapAddress, value, {
        gasLimit: 90000,
      })
      .then((response: any) => {
        response.wait(1)
          .then(async(receipt: any)=> {
            if (receipt.status == 1) {
              data.allowance = 'done'
              await refreshSwap(state)
              data.loadingButton = null
            }
          })
          .catch((err: any)=> {
            console.log(err)
          })
      })
      .catch((error: Error) => {
        console.debug('Failed to approve token', error)
        throw error
      })
  }

  if (data.maker.coin == null || data.loadingButton == "approve") {
    return (<ButtonPrimary style={{ marginBottom: '10px', background: 'rgb(238 38 40 / 74%)' }}>
        <Loading></Loading>
    </ButtonPrimary>)
  }
  else if (!isAllowed(data))
    return (
      <ButtonPrimary onClick={allow} style={{ marginBottom: '10px', background: 'rgb(238 38 40 / 74%)' }}>
        <Trans>Allow To Swap</Trans>
      </ButtonPrimary>
    )
  return null
}

export default renderAllowButton;