import getFee from './getFee'


const stripNumber = (str)=> {
  if (str) {
      const m = parseFloat(str)
      if (m) {
        return m.toString()
      }
      return 0;
  }
  
  return 0;
}

const changeAmount = (data, name) => (amount) => {
    const strippedAmount = stripNumber(amount)
    console.log('changeAmount', amount , strippedAmount )
    if(name == "maker") {
      data.maker.amount = strippedAmount      
      data.taker.amount = +strippedAmount - getFee(strippedAmount, data)
    }
    if(name == "taker") {
      data.maker.amount = +strippedAmount + getFee(strippedAmount, data)    
      data.taker.amount = strippedAmount
    }
    data.taker.fee.LP = getFee(amount, data)
  }

export default changeAmount;