import Web3 from 'web3'
import { NETWORK_URLS } from '../../connectors'
import POOL_ABI from '../../abis/pool.json'
import MULTICHAIN_ABI from '../../abis/multichain.json'
import POOL_CARDANO_ABI from '../../abis/pool_cardano.json'
import changeAmount from '../SwapForm/changeAmount.js'
import getLocalStorage from './getLocalStorage.js'
import { transaction } from 'mobx'
import processManager from './processManager'
import resetSwapInfo from '../resetSwapinfo'
import { makeHumanAmounts } from './makeHumanAmounts'
import { getBalanceInfo } from './getTokenInfo'
import { BigNumber } from "ethers";
import getNami from './getNami'
import ensureNami from './ensureNami'


async function refreshSwapInfoEvm(state: any) {
  const data = state.data
  const account = state.account
  const maker = data.coins[data.maker.coin]
  const takerCoinIndex = data.taker.coin;
  const makerWeb3 = new Web3(NETWORK_URLS[maker.chainId])
  const makerPoolContract = new makerWeb3.eth.Contract(POOL_ABI, maker.swapAddress)
  const makerBalanceInfo = await getBalanceInfo(maker, makerWeb3, account)
  const allowanceBN = makerBalanceInfo.allowanceBN;
  const makerBalance = makerBalanceInfo.balance;
  const makerPoolBalance = makerBalanceInfo.poolBalance;
  const networkFee = await makerPoolContract.methods.chainIdToFee(data.taker.chainId).call();
  //console.log("data.taker.chainId", maker.swapAddress, data.taker.chainId, networkFee.toString())
  const taker = data.coins[takerCoinIndex];
  const takerWeb3 = new Web3(NETWORK_URLS[taker.chainId])
  //const takerTokenContract = new takerWeb3.eth.Contract(ERC20_ABI, taker.tokenAddress)
  const takerPoolContract = new takerWeb3.eth.Contract(POOL_ABI, taker.swapAddress)
  const takerBalanceInfo = await getBalanceInfo(taker, takerWeb3, account)
  const takerBalance = takerBalanceInfo.balance
  const owner = await takerPoolContract.methods.owner().call();
  const takerMeshBalance = await takerWeb3.eth.getBalance(owner)
  const takerPoolBalance = takerBalanceInfo.poolBalance;
  transaction(()=> {
    data.maker.fee.Crosschain = Web3.utils.fromWei(networkFee, 'ether')
    console.log("crosschain fee", data.maker.fee.Crosschain) 
    //data.maker.coin = makerCoinIndex
    data.maker.balance = makeHumanAmounts(makerBalance, maker.decimals)
    data.maker.balancePool = makeHumanAmounts(makerPoolBalance, maker.decimals)
    data.maker.allowance = allowanceBN.toString();
    data.taker.coin = takerCoinIndex
    data.taker.balance = makeHumanAmounts(takerBalance, taker.decimals)
    data.taker.balancePool = makeHumanAmounts(takerPoolBalance, taker.decimals)
    data.taker.balanceEth = makeHumanAmounts(takerMeshBalance, "1000000000000000000")
    //data.maker.max = +data.maker.balance < +data.taker.balancePool ? data.maker.balance : data.taker.balancePool
    data.taker.max = +data.maker.balance < +data.taker.balancePool ? data.maker.balance : data.taker.balancePool
    if (+data.maker.amount == 0) {
      changeAmount(data, "maker")(data.taker.max)
    }
    
    
    
    const ls = getLocalStorage(data)
    ls.syncWithStore()
    
    //data.process.stage = 3;
    processManager.ensureProcess(data)
  })

}

const sum = (c, n)=> {
  return c.add(n)
}

const getBalance = (state, utxos)=> {
  //state.
  const maker = state.data.coins[state.data.maker.coin]
  const unit = maker.unit || "lovelace"
  return utxos.map( (x)=> x.amount.filter( (y)=> y.unit == unit )).flat(2).map((x)=> x.quantity).reduce(sum, BigNumber.from("0"))
}

const getNamiInfo = async(state, func)=> {
  
  if (window.cardano == null)
    return Promise.reject("Please install the Nami, CCVault or Yoroi wallet")

  if (state.data.nami.type == null) {

    state.data.nami.chooseWallet = true;
    const obj = document.getElementById("web3-status-connected")
          if (obj) {
            obj.click()
    }
    return Promise.reject("Please choose Nami Wallet")
  }

  const isEnabled = await window.cardano[state.data.nami.type].isEnabled();

  

  if (isEnabled) {
    const Nami = await getNami(state)
    return await Nami[func]()
  } else {
    
    const result = await cardano[state.data.nami.type].enable()
    if (result) {
      await ensureNami(state)
      const Nami = await getNami(state)
      return await Nami[func]()
    }
    else {
      return Promise.reject("Nami is not connected to the website")
    }
  }

}

async function shelley_to_evm(state: any) {
  
  const data = state.data;
  

  const utxos = await getNamiInfo(state, "getUtxos")
  

  const balanceLong = getBalance(state, utxos)
  const maker = data.coins[data.maker.coin]
  
  //const suggestedBalance = balanceLong.sub('1200000')

  //const finalBalance = +suggestedBalance.toString() < 0 ? balanceLong : suggestedBalance
    

  const balance = makeHumanAmounts(balanceLong , maker.decimals )

  //
  //const Nami = await nami_lib.NamiWalletApi(
  //      window.cardano,
  //      "testnetQN4s0cOd0RKTRmnqZ35C6xeylV6OD5pc"
  //)
  //window.Nami = Nami
  //const addr = await Nami.getAddress()
  //console.log({ addr })



  //let txHash = await Nami.send({
  //  address: "addr_test1qzjsrjwfe0zg6aqr8xxu8z0m8872a544l52pp57y9xtvt5vszwuh7qvfs03t3ytt04z4ysncqryj4thkhvat0mwpa2pskn2aqn",
  //  amount: 5,
  //  metadata: {
  //      msg: "0xc2f6C9eAF3076df062e7C2F0cC52c3eC0641BDd0"
  //  }
  //})

  

  transaction(()=> {
      data.maker.fee.Crosschain = "0"
      data.maker.balance = balance
      
      data.maker.balancePool = balance
      data.maker.allowance = "true"
      data.taker.recipient = state.account
      //data.taker.coin = takerCoinIndex
      data.taker.balance = "0"
      data.taker.balancePool = "99999999999999999"
      data.taker.balanceEth = "99999999999999999"
      data.maker.max = balance
      data.taker.max = balance
      //if (+data.maker.amount == 0) {

      const getAmount = ()=> {
          if ( (maker.unit || "lovelace") == "lovelace") { 
              return +data.maker.amount > 0 ? data.maker.amount : ( +balance - 2 > 0 ? (+balance - 2).toString()  : "0" );
          }
          else {
            return balance
          }
      }
      
      changeAmount(data, "maker")(getAmount())
    })

}

async function evm_to_shelley(state: any) {
  const data = state.data;
  const account = state.account
  const maker = data.coins[data.maker.coin];
  const makerWeb3 = new Web3(NETWORK_URLS[maker.chainId]);
  const swapAddress = maker.realSwapAddress || maker.swapAddress
  const makerPoolContract = new makerWeb3.eth.Contract(POOL_CARDANO_ABI, swapAddress);
  const networkFee = await makerPoolContract.methods.chainFee().call();

  const makerBalanceInfo = await getBalanceInfo(maker, makerWeb3, account)
  //const allowanceBN = makerBalanceInfo.allowanceBN;
  const makerBalance = makerBalanceInfo.balance;
  

   

  
  const recipient = await getNamiInfo(state, "getAddress")
  
  if (maker.realSwapAddress) {
    const makerBalanceInfo = await getBalanceInfo(maker, makerWeb3, account)
    const allowanceBN = makerBalanceInfo.allowanceBN;
    data.maker.allowance = allowanceBN.toString();
  } else {
    data.maker.allowance = "true"
  }
  
  const takerCoinIndex = data.taker.coin;
  const taker = data.coins[takerCoinIndex];
  transaction(()=> {
    data.taker.recipient = recipient
    data.maker.fee.Crosschain = Web3.utils.fromWei(networkFee.toString(), 'ether')
    //data.maker.coin = makerCoinIndex
    data.maker.balance = makeHumanAmounts(makerBalance, maker.decimals)
    data.maker.balancePool = makeHumanAmounts("99999999000000", maker.decimals)
    
    
    
    data.taker.coin = takerCoinIndex
    data.taker.balance = makeHumanAmounts(makerBalance, maker.decimals)
    data.taker.balancePool = makeHumanAmounts("99999999000000", taker.decimals)
    data.taker.balanceEth = makeHumanAmounts("99999999000000", taker.decimals)
    //data.maker.max = +data.maker.balance < +data.taker.balancePool ? data.maker.balance : data.taker.balancePool
    data.taker.max = data.maker.balance
    if (+data.maker.amount == 0) {
      console.log("maker balance", data.maker.balance , makerBalance , maker.decimals)
      changeAmount(data, "maker")(data.taker.max)
    }
    
    data.account = account;
    
    const ls = getLocalStorage(data)
    ls.syncWithStore()
    
    //data.process.stage = 3;
    processManager.ensureProcess(data)
  })

}

async function getChainInfo(makerPoolContract, data) {
  const chainId = chainIdToHex(data.taker.chainId);
  try {
    const res =  await makerPoolContract.methods.chainFee(chainId).call()
    if (res == "0") 
      throw "chain fee is not defined " + chainId + ' in ' + data.maker.chainId
    return res
  }
  catch(err) {
    return alert(err);
  }
}

async function multichain_unwrap(state: any) {

  const data = state.data
  const account = state.account
  const maker = data.coins[data.maker.coin]
  const takerCoinIndex = data.taker.coin;
  const makerWeb3 = new Web3(NETWORK_URLS[maker.chainId])
  const makerPoolContract = new makerWeb3.eth.Contract(MULTICHAIN_ABI, maker.swapAddress)
  const makerBalanceInfo = await getBalanceInfo(maker, makerWeb3, account)
  const allowanceBN = makerBalanceInfo.allowanceBN;
  const makerBalance = makerBalanceInfo.balance;
  const makerPoolBalance = makerBalanceInfo.poolBalance;



  const networkFee = await getChainInfo(makerPoolContract, data);
  //const networkFee = await makerPoolContract.methods.chainFee(chainIdToHex(data.taker.chainId)).call();
  //console.log("data.taker.chainId", maker.swapAddress, data.taker.chainId, networkFee.toString())
  const taker = data.coins[takerCoinIndex];
  const takerWeb3 = new Web3(NETWORK_URLS[taker.chainId])
  //const takerTokenContract = new takerWeb3.eth.Contract(ERC20_ABI, taker.tokenAddress)
  const takerPoolContract = new takerWeb3.eth.Contract(MULTICHAIN_ABI, taker.swapAddress)
  const takerBalanceInfo = await getBalanceInfo(taker, takerWeb3, account)
  const takerBalance = takerBalanceInfo.balance
  const owner = await takerPoolContract.methods.owner().call();
  const takerMeshBalance = await takerWeb3.eth.getBalance(owner)
  const takerPoolBalance = takerBalanceInfo.poolBalance;
  const fee = Web3.utils.fromWei(networkFee, 'ether')
  transaction(()=> {
    data.maker.fee.Crosschain = Web3.utils.isAddress(data.maker.referral) ? +fee * 2 : fee
    console.log("crosschain fee", data.maker.fee.Crosschain) 
    //data.maker.coin = makerCoinIndex
    data.maker.balance = makeHumanAmounts(makerBalance, maker.decimals)
    data.maker.balancePool = makeHumanAmounts(makerPoolBalance, maker.decimals)
    data.maker.allowance = allowanceBN.toString();
    data.taker.coin = takerCoinIndex
    data.taker.balance = makeHumanAmounts(takerBalance, taker.decimals)
    data.taker.balancePool = makeHumanAmounts(takerPoolBalance, taker.decimals)
    data.taker.balanceEth = makeHumanAmounts(takerMeshBalance, "1000000000000000000")
    //data.maker.max = +data.maker.balance < +data.taker.balancePool ? data.maker.balance : data.taker.balancePool
    data.taker.max = data.maker.balance //< +data.taker.balancePool ? data.maker.balance : data.taker.balancePool
    if (+data.maker.amount == 0) {
      changeAmount(data, "maker")(data.taker.max)
    }
    
    
    
    const ls = getLocalStorage(data)
    ls.syncWithStore()
    
    //data.process.stage = 3;
    processManager.ensureProcess(data)
  })



}



function chainIdToHex(chainId: any) {
  return Web3.utils.padLeft(Web3.utils.toHex(chainId), 8);
}

async function multichain_wrap(state: any) {

  const data = state.data
  const account = state.account
  const maker = data.coins[data.maker.coin]
  const takerCoinIndex = data.taker.coin;
  const makerWeb3 = new Web3(NETWORK_URLS[maker.chainId])
  const makerPoolContract = new makerWeb3.eth.Contract(MULTICHAIN_ABI, maker.swapAddress)
  const makerBalanceInfo = await getBalanceInfo(maker, makerWeb3, account)
  const allowanceBN = makerBalanceInfo.allowanceBN;
  const makerBalance = makerBalanceInfo.balance;
  const makerPoolBalance = makerBalanceInfo.poolBalance;
  
  const networkFee = await getChainInfo(makerPoolContract, data);
  //console.log("data.taker.chainId", maker.swapAddress, data.taker.chainId, networkFee.toString())
  const taker = data.coins[takerCoinIndex];
  const takerWeb3 = new Web3(NETWORK_URLS[taker.chainId])
  //const takerTokenContract = new takerWeb3.eth.Contract(ERC20_ABI, taker.tokenAddress)
  const takerPoolContract = new takerWeb3.eth.Contract(MULTICHAIN_ABI, taker.swapAddress)
  const takerBalanceInfo = await getBalanceInfo(taker, takerWeb3, account)
  const takerBalance = takerBalanceInfo.balance;
  //console.log("taker.swapAddress", taker.swapAddress) 
  const owner = await takerPoolContract.methods.owner().call();
  const takerMeshBalance = await takerWeb3.eth.getBalance(owner)
  const takerPoolBalance = "999999000000000000000000" //takerBalanceInfo.poolBalance;
  const fee = Web3.utils.fromWei(networkFee, 'ether')
  transaction(()=> {
    data.maker.fee.Crosschain = Web3.utils.isAddress(data.maker.referral) ? +fee * 2 : fee
    console.log("crosschain fee", data.maker.fee.Crosschain) 
    //data.maker.coin = makerCoinIndex
    data.maker.balance = makeHumanAmounts(makerBalance, maker.decimals)
    data.maker.balancePool = makeHumanAmounts(makerPoolBalance, maker.decimals)
    data.maker.allowance = allowanceBN.toString();
    data.taker.coin = takerCoinIndex
    data.taker.balance = makeHumanAmounts(takerBalance, taker.decimals)
    data.taker.balancePool = makeHumanAmounts(takerPoolBalance, taker.decimals)
    data.taker.balanceEth = makeHumanAmounts(takerMeshBalance, "1000000000000000000")
    //data.maker.max = +data.maker.balance < +data.taker.balancePool ? data.maker.balance : data.taker.balancePool
    data.taker.max = data.maker.balance //< +data.taker.balancePool ? data.maker.balance : data.taker.balancePool
    if (+data.maker.amount == 0) {
      changeAmount(data, "maker")(data.taker.max)
    }
    
    
    
    const ls = getLocalStorage(data)
    ls.syncWithStore()
    
    //data.process.stage = 3;
    processManager.ensureProcess(data)
  })


  async function getChainInfo(makerPoolContract, data) {
    const chainId = chainIdToHex(data.taker.chainId);
    try {
      const res =  await makerPoolContract.methods.chainFee(chainId).call()
      if (res == "0") 
        throw "chain fee is not defined " + chainId + ' in ' + data.maker.chainId
      return res
    }
    catch(err) {
      return alert(err);
    }
  }

}



const types = {
  evm_to_shelley, shelley_to_evm , refreshSwapInfoEvm, multichain_unwrap, multichain_wrap
}

async function refreshSwapInfo(state: any, reset) {
  
  const data = state.data
  const account = state.account
  const chainId = state.chainId

  data.account = account;

  if (reset)
    resetSwapInfo(state)

   

  const supportedMakerCoins = data.coins.filter((c)=> c.chainId == chainId)
  
  if (supportedMakerCoins.length == 0) {
    return
  }

  const makerCoinIndex =  
    data.maker.coin ? data.maker.coin : data.coins.indexOf(supportedMakerCoins[0])

  const maker = data.coins[makerCoinIndex];
  
  if (data.taker.chainId == null) {
    const taker = data.coins.find((c)=> c.group == maker.group)
    data.taker.chainId = taker.chainId
  }

  if (account == null || chainId == null) {
    return null
  }

  if (data.taker.coin == null) {
    data.maker.coin = makerCoinIndex
    return
  }

  
  
  
  return await (types[maker.type] || types.refreshSwapInfoEvm)(state)
  

  


}

export default refreshSwapInfo
