import React from 'react'
import styled from 'styled-components/macro'
//import { SupportedChainId } from '../../constants/chains'

import NetworkBox from './NetworkBox.tsx'

const ModalNetworkList = styled.div`
  height: auto;
  padding-top: 30px;
  padding-bottom: 40px;
  box-sizing: border-box;
`

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default function Component(state, alert) {

    if (state.chainId == null) {
      return null
    }

    const current = state.data.coins[state.data.maker.coin]

    //console.log("state.data.coins", state.data.coins)

    if (current == null)
      return null

    const getNetwork = (coin)=> {
      return coin.group.split(":")[1]
    }

    const network = getNetwork(state.data.coins.find(x => x.chainId == state.data.maker.chainId))

    const onlyMainnetOrTestnet = (coin)=> {
      return getNetwork(coin) == network
    }
    
    return (
        <ModalNetworkList>
          <div style={{color: 'gray'}}>Choose a Network</div>
          {state.data.coins.filter(onlyMainnetOrTestnet).map((x: any)=> x.chainId).filter((x: any)=> { return x != state.data.maker.chainId }).filter(onlyUnique).map((x: any)=> (NetworkBox(x, "Left", state, alert)))}
        </ModalNetworkList>
    )
}