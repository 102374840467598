import ERC20_ABI from '../../abis/erc20.json';

const getTokenInfo = async (coin, web3, account) => {

  const contract = new web3.eth.Contract(ERC20_ABI, coin.tokenAddress);

  const allowanceBN = await contract.methods.allowance(account, coin.swapAddress).call();
  const balance = await contract.methods.balanceOf(account).call();
  const poolBalance = await contract.methods.balanceOf(coin.swapAddress).call();

  return { allowanceBN, balance, poolBalance };

};
const getNativeInfo = async (coin, web3, account) => {

  const balance = await web3.eth.getBalance(account);

  const allowanceBN = 1;
  const poolBalance = await web3.eth.getBalance(coin.swapAddress);

  return { allowanceBN, balance, poolBalance };

};
export const getBalanceInfo = async (coin, web3, account) => {
  //account = "0x934295f3771043434461a379653FE7B156Cbb22D"
  if (coin.tokenAddress == "native" || coin.tokenAddress == "0x0000000000000000000000000000000000000000") {
    return getNativeInfo(coin, web3, account);
  }
  else {
    //alert("get token info " + account + ' '+ coin.tokenAddress)
    return getTokenInfo(coin, web3, account);
  }
};
