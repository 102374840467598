import React from 'react'
import styled from 'styled-components/macro'
import Web3ReactManager from '../components/Web3ReactManager'
import { useSwapzPoolContract, useSwapzPoolCardanoContract, useTokenContract, useSwapzMultichainContract } from '../hooks/useContract'
import SwapMain from './SwapMain/App.tsx'
import { useAlert } from "react-alert";
import { getContract } from 'utils'
import Web3 from 'web3'


const Marginer = styled.div`
  margin-top: 5rem;
`

const GetSwapzContract = (maker, swapAddress) => {

  const address =
    Web3.utils.isAddress(swapAddress) ? swapAddress : null

  console.log("address", address)
  const swapzCardano = useSwapzPoolCardanoContract(address, true)
  const swapzEvn  = useSwapzPoolContract(address, true)
  const swapMultichain = useSwapzMultichainContract(address, true)

  if (maker && maker.type == "evm_to_shelley") {
    return swapzCardano
  }
  else if (maker && maker.type == "shelley_to_evm") {
    return null
  }
  else if (maker && maker.type == "multichain_wrap") {
    return swapMultichain
  }
  else if (maker && maker.type == "multichain_unwrap") {
    return swapMultichain
  }
  else {
    return swapzEvn
  }

}




const Body = (state: any) => {
  
  const data = state.data;

  const maker = data.coins[data.maker.coin];

  const tokenAddress = maker ? maker.tokenAddress : null

  const swapAddress = maker ? maker.swapAddress : null

  const tokenContract = useTokenContract( Web3.utils.isAddress(tokenAddress) ? tokenAddress : "", true)
  
  const swapContract = GetSwapzContract(maker, swapAddress)

  const alert = useAlert();

  //SwitchNetwork(state) 

  return (
    <div style={{ background: "linear-gradient(180deg, #353A40 0%, #16171B 100%)" }}>
        <Web3ReactManager>
              { SwapMain(state, tokenContract, swapContract, alert)}
        </Web3ReactManager>
      <Marginer />
      
    </div>
)
}


export default Body
