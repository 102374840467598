//import { useSwapzPoolContract, useTokenContract } from '../../hooks/useContract'
import { Trans } from '@lingui/macro'
import React from 'react'
import styled, { keyframes }  from 'styled-components'
import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { MaxUint256 } from '@ethersproject/constants'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
//import AppBody from '../AppBody'
import { thousandsSeparators } from 'currency-thousand-separator'
//import { observer } from 'mobx-react'
import refreshPoolInfo from '../PoolInfo/refreshPoolInfo.js'
//import { BigNumber } from 'ethers'
//import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
//import SwapInfo from '../PoolInfo'
import LogoDark from '../../assets/svg/logo_white.svg'
import { normalizeAmount } from '../SwapForm/normalizeAmount'
import chooseNetwork from './chooseNetwork'
import { NETWORK_LABELS } from '../../constants/chains'
import renderAmount from '../SwapForm/renderAmount';
import getFee from '../SwapForm/getFee'
import Web3 from 'web3'
import { Loading } from './Loading'
import renderAllowButton from './renderAllowButton'
//import User from '../../assets/svg/user.svg'
import CloudOff from '../../assets/svg/cloud-off.svg'
import { Message, MessageIcon, MessageText } from './Message'
import ConnectWallet from '../SwapMain/ConnectWallet.tsx'
import { isAllowed } from './isAllowed'

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

const JoinButton = styled(ButtonPrimary)`
  //background: linear-gradient(-90deg, #2172E5, #2172E580, #2172E5, #2172E580);
  animation: ${gradient} 15s ease infinite;
`

const Container = styled.div`
  display: inline-block;
  text-align: left;
  width: 350px;
  padding: 0 10px;
  @media (max-width: 350px) {
    width: 100%
  }
`

const Info = styled.div`
  display: block;
  text-align: center;
  padding: 80px;
  color: gray;
  font-size: 20px;
`

const PoolForm = (state: any, tokenContract: any, swapContract: any, alert: any) => {
  
  const data = state.data
  const chainId = state.chainId
  const account = state.account

  if (data.coins.filter((c)=> c.chainId == chainId).length == 0)
    return (
      <Message>
            <MessageIcon src={CloudOff} alt={'Account'} />
            <MessageText><div>Please connect to a swapz supported network</div></MessageText>
      </Message>)

  if (account == null) {
      return (
        <div style={{paddingTop: '20px'}}>
              <ConnectWallet />
        </div>)
  }

  if(data.maker.coin == null)
    return null

  if(data.coins[data.maker.coin].disabledPool) {
    //alert.show("Pool is disabled");
    return <Info>Pool is disabled</Info>
  }

  if(data.taker.coin == null)
    return null


  //const checkTxId = () => {
  //  const link = getExplorerLink(chainId, data.stakeHashId, ExplorerDataType.TRANSACTION)
  //  window.open(link)
  //}

  const handleSuccess = (name: string) => (res: any)=> {
    if (res.hash) {
      data.stakeHashId = res.hash
      res.wait(1)
        .then(async(receipt: any)=> {
          //console.log("receipt", receipt)
          if (receipt.status != 1) {
            handleError(name)("transaction is rejected")
          }
          else {
            await refreshPoolInfo(state)
            data.loadingButton = null
          }
          
        })
        .catch(handleError(name))
      }
      else {
        handleError(name)(res)
      }
  }

  const handleError = (name: string) => (res: any)=> {
    data.loadingButton = null
    alert.show("Transaction failed", {type: 'error'});
  }


  const makeAction = (name: string, func: any)=> ()=> {
    if (swapContract == null || data.loadingButton)
      return
    data.loadingButton = name
    func()
      .then(handleSuccess(name))
      .catch(handleError(name))
  } 

  const join = makeAction("join", () => {
    const { longRoundedAmount, roundedAmount } = normalizeAmount(data.maker.amount, data.coins[data.maker.coin].decimals)
    data.maker.amount = roundedAmount
    console.log("join", longRoundedAmount)

    const value = data.coins[data.maker.coin].tokenAddress == "native" ? longRoundedAmount : 0

    console.log("value", value.toString());

    return swapContract.join(longRoundedAmount, { gasLimit: 800000, value })
  });

  const withdrawAmount = data.maker.totalShares == 0 ? 0 : (data.maker.amountLP * data.maker.totalLiquidity / data.maker.totalShares)
  console.log("data.maker.totalLiquidity", data.maker.totalLiquidity, "data.maker.totalShares", data.maker.totalShares)


  const risk = (function(){
    switch (false) {
    case !(+data.taker.amountEth < 0.3 && data.maker.chainId != data.taker.chainIdPool):
      return "The network balance is too small to send a transaction";
    case !(+data.taker.balancePool - +withdrawAmount < 1000):
      return `Liquidity is too low. Balance of pool is ${thousandsSeparators(data.taker.balancePool)} ${data.coins[data.taker.coin].text}`;
    default:
      return null;
    }
  }());

  const leaveMessage = (function(){
    switch (false) {
    case !(+data.taker.amountEth < 0.1 && data.maker.chainId != data.taker.chainIdPool):
      return "The network balance is too small"
    case !(+data.taker.balancePool - +withdrawAmount < 0):
      return "Liquidity is too low"
    default:
      return null;
    }
  }());


  const leave = makeAction("leave", () => {

    if (leaveMessage) {
      return Promise.reject(leaveMessage)
    }

    const { longRoundedAmount, roundedAmount } = normalizeAmount(data.maker.amountLP, data.coins[data.maker.coin].decimals)
    data.maker.amountLP = roundedAmount
    console.log("leave", data.maker.chainId, data.taker.chainIdPool)
    if (data.maker.chainId == data.taker.chainId && data.maker.assetId == data.taker.assetId) {
      return swapContract.leave(longRoundedAmount, { gasLimit: 400000 })
    } else {
      console.log("longRoundedAmount", longRoundedAmount, data.maker.fee.Crosschain, data.taker.chainIdPool, data.taker.assetId)
      //console.log("leaveForeign", longRoundedAmount.toString(), data.taker.chainId, data.taker.assetId, Web3.utils.toWei(data.maker.fee.Crosschain, "ether"))
      return swapContract.leaveForeign(longRoundedAmount, data.taker.chainIdPool, data.taker.assetId, { gasLimit: 400000, value: Web3.utils.toWei(data.maker.fee.Crosschain, "ether")  })
    }
  });

  const allow = makeAction("approve", () => {
    const coin = data.coins[data.maker.coin];
    const value = +data.maker.amount > 0 ? (Math.ceil(+data.maker.amount) + coin.decimals.replace("1", "") ) : MaxUint256
    
    return tokenContract.approve(coin.swapAddress , value, { gasLimit: 400000 })
  })

  const changeAmountAbstract = (field: string) => (event:any)=> {
    data.maker[field] = event
  }

  const changeAmount = changeAmountAbstract("amount")

  const changeAmountLP = changeAmountAbstract("amountLP")


  
  
  

  

  const exitPoolButton = () => {

    const background = risk ? 'red' : '#1b8414'

    if (data.maker.coin == null || data.loadingButton == "leave") {
      return (<ButtonPrimary style={{ background }}>
          <Loading></Loading>
      </ButtonPrimary>)
    }
    else {
      return (
        <div>
          <ButtonPrimary onClick={leave} style={{ background, borderRadius: "30px 0 0 30px", width: "50%", display: "inline-block" }}>
            <Trans>Leave</Trans>
          </ButtonPrimary>
          <ButtonPrimary style={{ borderRadius: "0 30px 30px 0", width: "50%", display: "inline-block", padding: "8px", background: "#2c3038" }}>
            {chooseNetwork(state)}
          </ButtonPrimary>
        </div>)
    }
  }
  

  const notAllowed = data.coins[data.maker.coin].tokenAddress != "native" && !isAllowed(data)

  const renderDepositButton = () => {
    if (notAllowed) {
      return null
    }
    else if (data.maker.coin == null || data.loadingButton == "join") {
      return (<ButtonPrimary>
          <Loading></Loading>
      </ButtonPrimary>)
    }
    else
      return (
        <JoinButton onClick={join}>
          <Trans>Join</Trans>
        </JoinButton>
      )
  }

  const Chevron = styled.div`
    display: inline-block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAfCAYAAADqUJ2JAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAHwAAAABiOXbkAAABcklEQVRIDa3VzW2DMBQH8GdCRXNLD0EiJ4/QEcgG7QgdoRu0G3SDdoRuQEfoBuGGxCU5cii4728BIsUGG2opfBi/H7zwbARxO59Ou3q7fSeldhuip7skydHv0wIMrqMoZeSBD9OaKDsXhUS/T9NQE4Y5CXFpA+USTHR3LcvyPmiaDOm1fTmneXRNs4cQvAa7gtZgI2gppv9sBA9bHMffTRAc/74ApD4cNzw2QhjQY0R5GyDxMmyYMbU2UO8Krqkbri0+kbqDywRPixvp83YzC2GcC+YEuWDO0BzmBU1h3pANs75+BNjabVVdlFLdJCfMT+8nwtr1E0WZEKIvTkX06gXZkH2SvDhDWOywTnG6sksZTwIE506QEVHqeX84vHXoLGRCGl7X4yT56BDsJyFXZBLyQayQL2KEliAjaClyBa1BemiE8CrIc+mRi+0Lg1xaiEFcsSnvJP+4IMxLqb42sdGzf1NVnzxGf7ZN6/FE/P9f+gWJ5ORfQgouPAAAAABJRU5ErkJggg==);
    width: 8px;
    height: 13px;
    background-size: cover;
    transform: ${({ direction }) => direction == "top" ? "rotate(270deg)" : "rotate(90deg)"  };;
    cursor: pointer;
    position: absolute;
    width: 11px;
    height: 20px;
  `



  const BalanceContainer = styled(AutoColumn)`
    text-align: left;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    background: #00000012;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    height: 60px;
    background-size: 400% 400%;
    
  `

  const BalanceHolder = ({text, balance, coin}: any)=> {
    return (
      <BalanceContainer>
        <div style={{ color: 'gray', fontSize: "15px"}}>{text}</div>
        <div style={{ paddingTop: "5px" }}>
          <span style={{ maxWidth: '120px', textOverflow: "ellipsis", overflow: "hidden", display: "inline-block" }} title={balance}>{thousandsSeparators(balance)}</span> 
          <span style={{ display: "inline-block", verticalAlign: "top", width: "3px" }}></span>
          <span style={{ display: "inline-block", verticalAlign: "top", color: "#5a5a5a" }}> {coin}</span>
        </div>
      </BalanceContainer>
    )
  }

  //const options = [
  //  { value: 'chocolate', label: 'Chocolate' },
  //  { value: 'strawberry', label: 'Strawberry' },
  //  { value: 'vanilla', label: 'Vanilla' }
  //]

  //console.log("toSmall", tooSmall, data.maker.balancePool, withdrawAmount);

  //console.log("toSmall", data.maker.chainId, data.taker.chainId)

  const fee = data.taker.chainIdPool != data.maker.chainId ? getFee(withdrawAmount, data) : 0
  
  if (data.coins[data.maker.coin] == null)
    return

  const inputField = notAllowed ? null :
    (
      <>
      <BalanceHolder text="Your Balance" balance={data.maker.balance} coin={data.coins[data.maker.coin].text} />
      <CurrencyInputPanel
        value={data.maker.amount}
        id="swap-currency-output"
        onUserInput={changeAmount}
        currency={data.coins[data.maker.coin]} />
      </>
    );
  return (
    <div>
    <Container>
        <Wrapper id="swap-page">
          <AutoColumn gap={'md'}>
          <div style={{ display: 'relative', marginTop: 40 }}>
            {inputField}
            </div>
            <BottomGrouping>
              {renderAllowButton(data, allow)}
              {renderDepositButton()}
            </BottomGrouping>
          </AutoColumn>
          { +data.maker.balanceLP == 0 ? null : 
          <AutoColumn gap={'md'}>
            <div style={{ display: 'relative', marginTop: 40 }}>
            <div style={{padding: '5px 0'}}>
              </div>
              <BalanceHolder text="Your Liquidity" balance={data.maker.balanceLP} coin='LP' />
              <CurrencyInputPanel
                value={data.maker.amountLP}
                id="swap-currency-output"
                onUserInput={changeAmountLP}
                currency={{ chainId, logo: LogoDark, text: "LP" }}
              />
            </div>
            <BalanceHolder text="Withdraw Amount" balance={renderAmount(withdrawAmount - fee)} coin={data.coins[data.taker.coin].text} />
            <BottomGrouping>
              {exitPoolButton()}
            </BottomGrouping>
            <div>
              { risk ? <div style={{ textAlign: "center", color: "red" }}>{risk}</div> : null }
            </div>
            <div style={{textAlign: "center"}}>
              { data.expandAllInfo == false ? (<div onClick={()=> { data.expandAllInfo = true }}><Chevron direction="bottom" /></div>) : 
              (<div>
                { fee > 0 ? (<BalanceHolder text="Withdraw Fee" balance={renderAmount(fee)} coin={data.coins[data.taker.coin].text} />) : null }
                <BalanceHolder text={NETWORK_LABELS[data.taker.chainIdPool] + " Pool Balance"} balance={data.taker.balancePool} coin={data.coins[data.taker.coin].text} />
                <BalanceHolder text={NETWORK_LABELS[data.maker.chainId] + " Pool Liquidity"} balance={data.maker.totalLiquidity} coin={data.coins[data.maker.coin].text} />
                <BalanceHolder text={NETWORK_LABELS[data.maker.chainId] + " Total LP Tokens"} balance={data.maker.totalShares} coin='LP' />
                <div onClick={()=> { data.expandAllInfo = false }}><Chevron direction="top" /></div>
              </div>) }
            </div>
            
          </AutoColumn>
          }
        </Wrapper>
    </Container>
    </div>
  )
}

export default PoolForm
