import { Pair } from '@uniswap/v2-sdk'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import React, { useState, useCallback, ReactNode } from 'react'
import styled from 'styled-components/macro'
import CurrencyLogo from '../CurrencyLogo'
import { RowFixed } from '../Row'
import { Input as NumericalInput } from '../NumericalInput'
import { Trans } from '@lingui/macro'
import LogoDark from '../../assets/svg/logo_white.svg'
import { SendControls } from '../../pages/SwapMain/components/SendControlsComponent'
import { SendInput } from '../../pages/SwapMain/components/SendInputComponent'


const InputPanel = styled.div<{ hideInput?: boolean }>`
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  //background-color: ${({ theme, hideInput }) => (hideInput ? 'transparent' : theme.bg2)};
  z-index: 1;
  width: 100%;
`


const Swapz = styled.div`
  position: absolute;
  top: 13px;
  left: 5px;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 8px;
  justify-content: space-between;
`


const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`


const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.25rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size:  ${({ active }) => (active ? '18px' : '18px')};
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: CurrencyAmount<Token> | null
  priceImpact?: Percent
  id: string
  showCommonBases?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  readonly? : boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onCurrencySelect,
  currency = { chainId: 56, blockchain: 'binance', logo: LogoDark, text: "Swapz" },
  otherCurrency,
  id,
  showCommonBases,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  readonly = false,
  ...rest
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  //const { account } = useActiveWeb3React()
  //const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  //const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
        <SendControls>
          <Swapz>
            <Aligner>
                <CurrencyLogo currency={currency} />
              <RowFixed>
                <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
      <Trans>{currency.text}</Trans>
                </StyledTokenName>
              </RowFixed>
            </Aligner>
          </Swapz>
          <SendInput>
          <NumericalInput
            className="token-amount-input"
            value={value}
            readonly={readonly}
            onUserInput={(val) => {
              onUserInput(val)
            }}
          />
          </SendInput>
        </SendControls>
    </InputPanel>
  )
}
