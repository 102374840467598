import React from 'react'
import styled from 'styled-components/macro'

import ModalNetworksContainer from './ModalNetworksContainer.tsx'

const ModalNetworksPlaceholder = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0px;
  text-align: center;
  top: -320px;
  transition: top 0.3s;
  visibility: hidden;
  height: auto;
  &.active
{
    top: 0;
    visibility: visible;
}
`

export default function Component(allData, alert) {
    const className = allData.data.expandNetworkList ? "active" : ""
    return (
        <ModalNetworksPlaceholder className={className}>
          {ModalNetworksContainer(allData, alert)}
        </ModalNetworksPlaceholder>
    )
}