import React from 'react'
import styled from 'styled-components/macro'

import decors from './decors.tsx'

const ExpandMoreButton = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 80px;
  height: 80px;
  position: relative;
  transform: rotate(-180deg);
  >.decor1
{
    top: 1px;
    left: 1px;
    position: absolute;
    width: 78px;
    height: 78px;
    background: linear-gradient(318.21deg, #1D2328 14.05%, #131314 85.29%);
    box-shadow: 14px 14px 40px rgba(16, 16, 18, 0.75), -7px -7px 30px #262E32;
    border-radius: 100px;
    transform: rotate(-180deg);
}
  >.decor2
{
    top: 4px;
    left: 4px;
    position: absolute;
    width: 72px;
    height: 72px;
    background: linear-gradient(320.66deg, #2F353A 14.75%, #1C1F22 84.81%);
    box-shadow: inset 0px 0px 3px rgba(63, 63, 63, 0.45);
    border-radius: 100px;
    transform: rotate(-180deg);
}
  >.decor3
{
    display: inline-block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAfCAYAAADqUJ2JAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAHwAAAABiOXbkAAABcklEQVRIDa3VzW2DMBQH8GdCRXNLD0EiJ4/QEcgG7QgdoRu0G3SDdoRuQEfoBuGGxCU5cii4728BIsUGG2opfBi/H7zwbARxO59Ou3q7fSeldhuip7skydHv0wIMrqMoZeSBD9OaKDsXhUS/T9NQE4Y5CXFpA+USTHR3LcvyPmiaDOm1fTmneXRNs4cQvAa7gtZgI2gppv9sBA9bHMffTRAc/74ApD4cNzw2QhjQY0R5GyDxMmyYMbU2UO8Krqkbri0+kbqDywRPixvp83YzC2GcC+YEuWDO0BzmBU1h3pANs75+BNjabVVdlFLdJCfMT+8nwtr1E0WZEKIvTkX06gXZkH2SvDhDWOywTnG6sksZTwIE506QEVHqeX84vHXoLGRCGl7X4yT56BDsJyFXZBLyQayQL2KEliAjaClyBa1BemiE8CrIc+mRi+0Lg1xaiEFcsSnvJP+4IMxLqb42sdGzf1NVnzxGf7ZN6/FE/P9f+gWJ5ORfQgouPAAAAABJRU5ErkJggg==);
    width: 8px;
    height: 13px;
    background-size: cover;
    transform: rotate(270deg);
    position: absolute;
    top: 29px;
    width: 11px;
    height: 20px;
    left: 34px;
}
`

export default function Component(allData) {
    return null;
    const expand = ()=> {
      allData.data.expandOptionsList = []
    }
    return (
        <ExpandMoreButton onClick={expand}>
          {decors()}
        </ExpandMoreButton>
    )
}