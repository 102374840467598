import { Trans } from '@lingui/macro'
//import useScrollPosition from '@react-hook/window-scroll'
import React, { useState } from 'react'
import { Text } from 'rebass'
import { useETHBalances } from 'state/wallet/hooks'
import styled from 'styled-components/macro'
import Logo from '../../assets/svg/logo.svg'
import { NETWORK_LABELS, SupportedChainId } from '../../constants/chains'
import Modal from '../Modal'
import { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import UniBalanceContent from './UniBalanceContent'
import Navigate from '../../pages/Navigate'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  height: 100px;
  grid-template-columns: 60% 40%;
  @media (max-width: 840px) {
    grid-template-columns: 40% 60%;
  }
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  padding-top: 20px
  z-index: 21;
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`}
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position .1s, box-shadow .1s;
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: right;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  border-radius: 9px;
`
const HeaderRow = styled(RowFixed)`
  padding-left: 4px;
`
const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  margin-top: -9px;
  flex-direction: row;
  align-items: center;
  border-radius: 12px; !important;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`



const HideSmall = styled.span`
  display: inline-block;
  background: #2d3136;
  display: none;
`

const NetworkCard = styled.div`
  border-radius: 12px 0 0 12px;
  text-align: center;
  max-width: 130px;
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const LogoText = styled.h4`
  @media (max-width: 832px) {
    display: none;
  }
`



const Header =  (state:any)=> {
  
  const account = state.account 
  const chainId = state.chainId

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)

  const resetView = ()=>  {
    //data
    state.data.taker.coin = null;
    Navigate(state, "Swap")
  }
  return (
    <HeaderFrame>
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      <HeaderRow>
        <Title href="#">
          <UniIcon onClick={resetView}>
            <img width={'32px'} src={Logo} alt="logo" />
          </UniIcon>
        </Title>
        <LogoText>Swapz</LogoText>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          <HideSmall>
            {chainId && chainId !== SupportedChainId.MAINNET && NETWORK_LABELS[chainId] && (
              <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
            )}
          </HideSmall>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                <Trans>{userEthBalance?.toSignificant(4)} ETH</Trans>
              </BalanceText>
            ) : null}
            {Web3Status(state)}
          </AccountElement>
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}

export default Header;