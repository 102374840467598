import React from 'react'
import { SendControls } from './components/SendControlsComponent'
//import SendDropDownCoins from './SendDropDownCoins.tsx'
import SearchInput from './SearchInput.tsx'
import CurrencyLogo from '../../components/CurrencyLogo'
import SwapButton from './SwapButton'
import styled from 'styled-components/macro'
import refreshSwapInfo from '../SwapInfo/refreshSwapInfo'
import { updateQuery } from '../updateQuery'

const Container = styled.div`
  display: block;
  position: relative;
  text-align: left;
  width: 100%;
  border-radius: 25px;
  cursor: pointer;
  height: 50px;
  &:hover {
    background-color: #53575630;
    box-shadow: 0px 0px 0px 5px #53575630;
    
  }
`

const Text = styled.div`
  display: inline-block;
  text-align: left;
  line-height: 50px;
  width: 33%;
  vertical-align: top;
`

function compare( a, b ) {
  if ( a.text < b.text ){
    return -1;
  }
  if ( a.text > b.text ){
    return 1;
  }
  return 0;
}


export default function Component(state: any, alert: any) {

    const data = state.data;

    const maker = data.coins[data.maker.coin];

    if (maker == null)
      return null
    


    const groups = data.coins.filter((x:any)=> x.chainId == data.taker.chainId ).map((x: any)=> x.group);

    const search = (coin: any)=> {
      return coin.text.toLowerCase().indexOf(state.data.maker.search.toLowerCase()) > -1
    }

    const coins = 
      data.coins.filter((x:any)=> x.chainId == maker.chainId && groups.indexOf(x.group) > -1 && x.disabled != true ).filter(search).sort(compare);

    const showSearch = state.data.maker.search.length > 0 || coins.length > 5
    

    if (coins.length == 0 && showSearch == false) {
      return <div style={{color: "rgb(110 110 110)"}}>There are no coins in this direction.</div>
    }
    
    
    const initSwap = (coin: any)=> async ()=> {
      state.data.maker.coin = state.data.coins.indexOf(coin)
      const maker = state.data.coins[state.data.maker.coin]
      const taker = state.data.coins.find((x: any) => x.group == maker.group && x != maker && x.chainId == state.data.taker.chainId)
      state.data.taker.coin = state.data.coins.indexOf(taker)
      state.data.loading = true;
      try {
        const res = await refreshSwapInfo(state, false)
        updateQuery(state)
        state.data.loading = false;      
        alert.info("This software is beta. Please use it at your own risk")
      }
      catch(err) {
        state.data.taker.coin = null;
        state.data.loading = false;
        alert.info("" + err)
      }
    }

    const getOppositeCoin = (coin)=> {
      const taker = data.coins.find((x: any)=> x.chainId == data.taker.chainId &&  x.group == coin.group)
      if (taker.text == coin.text) return ""
      return `to ${taker.text}`
    }

    return (
      <div>
        { !showSearch ? null :  (<SendControls style={{ marginBottom: "18px" }}>
          { SearchInput(state) }
        </SendControls>) }
        { coins.map((coin: any)=> 
          (<Container key={coin.assetId + coin.group} onClick={initSwap(coin)}>
            <Text style={{ width: '15%', paddingTop: "8px" }}>
            <CurrencyLogo currency={coin} size='30px' />
            </Text>
            <Text style={{ textAlign: 'left', width: '15%' }}> { coin.text }</Text>
            <Text style={{ textAlign: 'left', width: '30%', color: 'gray' }}> { getOppositeCoin(coin) }</Text>
            <Text style={{ textAlign: 'right', width: "36%" }}>
              {SwapButton(state, coin, alert)}
            </Text>
          </Container>)
        ) }
      </div>
    )

}