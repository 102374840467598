import React from 'react'
import Header from '../components/Header'
import ErrorBoundary from '../components/ErrorBoundary'
import styled from 'styled-components/macro'
import { observer } from 'mobx-react'
import { observable, reaction } from 'mobx'
import Swap from './Swap'
import { useActiveWeb3React } from '../hooks/web3'
import Navigate from './Navigate'
import { useEffect } from 'react'
import Coins from 'Coins.json'
import { SupportedChainId } from '../constants/chains'
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { tryAddToken } from './SwapForm/addToken'
import ensureNami from './SwapInfo/ensureNami.js'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 2;
`


function getPage() {
  const params = location.hash.replace('#/', '').replace("#", "").split("/")
  if(params[0].length == 0) 
    return "Swap"
  return toTitleCase(params[0]); 
}

function getMakerAddress() {
  const params = location.hash.replace('#/', '').replace("#", "").split("/")
  if(params.length == 1) 
    return null
  return params[1]
}

const disableCoins = (coin: any)=> {
  //if(coin.group == "wag:mainnet") {
  //  return window.location.href.indexOf('test=true') > -1
  //}
  return true

}

const state = observable({
  root: {
    page: getPage(),
    makerAddress: getMakerAddress(),
    accountHash: null,
    requestChainId: null
  },
  data: {
    process: {
      stage: 0
    },
    apiKeys:  {
      "cardano:0" : "testnetQN4s0cOd0RKTRmnqZ35C6xeylV6OD5pc",
      "cardano:1" : "mainnetOu6Yn21ex7YL9WTAPpnsy0GIQp1gOSbb"
    },
    loading: true,
    loadingButton: null,
    expandAllInfo: false,
    expandNetworkList: null,
    expandOptionsList: null,
    account: null,
    nami: {
      isInstalled: false,
      isConnected: false,
      account: "",
      type: localStorage.getItem("cardanoWallet"),
      chooseWallet: false
    },
    coins: Coins.filter(disableCoins),
    maker: {
      coin: null,
      search: "",
      referral: "",
      options: [],
      amount: 0,
      balance: 0,
      balanceLP: 0,
      amountLP: 0,
      totalLiquidity: 0,
      totalShares: 0,
      balancePool: 0,
      allowance: 0,
      volumeLast24: 0,
      totalTrades: 0,
      max: 0,
      confirmationPercent: 0,    
      fee: {
        LP: 0,
        Crosschain: 0.01
      }
    },
    taker: {
      coin: null,
      recipient: "",
      chainId: SupportedChainId.MAINNET,
      chainIdPool: null,
      amount: 0,
      balance: 0,
      balancePool: 0,
      balanceEth: 0,
      volumeLast24: 0,
      totalTrades: 0,
      max: 0,
      confirmationPercent: 0,
      fee: {
        LP: 0,
        Crosschain: 0.01
      }
    }
  }
})

async function traceUser(account: string) {
  fetch(`https://cross2.swapz.app/info?address=${account}`).catch(console.log)
}

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};


 
async function firstNavigate(state: any) {

  const data = state.data

  const [makerChainId, takerChainId] = (state.root.makerAddress || "").toString().split("_")

  const maker = data.coins.find((x: any)=> x.chainId == makerChainId) || data.coins.find((x: any)=> x.chainId == state.chainId)

  if (maker == null) {
    return Navigate(state, state.root.page)
  }

  const group = 
    data.coins.filter((x: any)=> x.chainId == maker.chainId).map((x:any)=> x.group ).sort().reverse()[0]

  
  const taker = data.coins.find((x: any)=> x.chainId == takerChainId) || data.coins
  .find((x:any)=> x.chainId != maker.chainId && x.group == group )

  

  if (maker != null) {
    data.maker.chainId = maker.chainId
  }
  else {
    data.maker.chainId = state.chainId
  }
  
  if (taker != null) {
    data.taker.chainId = taker.chainId
  }

  const chainId = maker.chainId.toString().indexOf('cardano') > -1 ? taker.chainId : maker.chainId


  if (taker && maker && state.chainId && chainId != state.chainId) {

    state.data.loading = false;
    state.root.requestChainId = chainId
    return 
  }

  if (data.maker.chainId.toString().indexOf("cardano") > -1 || data.taker.chainId.toString().indexOf("cardano") > -1) {
    await ensureNami(state)
  }

  return Navigate(state, state.root.page)
  //state.data.loading = true
  /*
  switchNetwork(coin.chainId, (err: any)=> {
    //state.data.loading = false
    if (err) {
      console.log("first navigate", 9)
      Navigate(state, state.root.page)
    }
    console.log("first navigate", 10)
    Navigate(state, state.root.page)
  })
  */


}


const Body = observer(({ state }: any) => {
  const { chainId, account } = useActiveWeb3React()
  const newState = { ...state, chainId, account }
  if (newState.data.maker.chainId == null) {
    newState.data.maker.chainId = chainId
  }
  useEffect(()=> {
    const accountHash = chainId && account ? chainId + account : 'na'
    
    if (state.root.accountHash == null || state.root.accountHash != accountHash) {
      //location.hash = "#/"
      newState.root.accountHash = accountHash
      traceUser(accountHash);
      tryAddToken(newState)
      firstNavigate(newState)
      
      
    }
  })
  return (
    <ErrorBoundary>
        <AppWrapper>
          <HeaderWrapper>
            {Header(newState)}
          </HeaderWrapper>
          { Swap(newState) }
        </AppWrapper>
      </ErrorBoundary>
  )
});

export default function App() {
  
  return (
    <Provider template={AlertTemplate} {...options}>
      <Body state={state} />
    </Provider>
  )
}
