import React from 'react'
import { SendInput } from './components/SendInputComponent';

export default function Component(allData: any) {
    //return null

    const changeLocal = (e: any)=> {
        allData.data.maker.search = e.target.value;
    }
    return (
        <SendInput><input placeholder='Search' value={allData.data.maker.search} style={{width: '200px', textAlign: 'left' }} onChange={changeLocal} /></SendInput>
    )
}