import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { Input } from '../Input'
import { SendControls } from '../../pages/SwapMain/components/SendControlsComponent'
import { SendInput } from '../../pages/SwapMain/components/SendInputComponent'


const InputPanelStyles = styled.div<{ hideInput?: boolean }>`
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  //background-color: ${({ theme, hideInput }) => (hideInput ? 'transparent' : theme.bg2)};
  z-index: 1;
  width: 100%;
`


interface InputPanelProps {
  value: string
  onUserInput: (value: string) => void
  label?: ReactNode
  id: string
  showCommonBases?: boolean
  readonly? : boolean
  placeholder: string
}

export default function InputPanel({
  value,
  onUserInput,
  id,
  readonly = false,
  placeholder,
  ...rest
}: InputPanelProps) {


  

  return (
    <InputPanelStyles id={id} {...rest}>
        <SendControls>
          <SendInput>
          <Input
            className="token-amount-input"
            placeholder={placeholder}
            value={value}
            readonly={readonly}
            onUserInput={(val) => {
              onUserInput(val)
            }}
          />
          </SendInput>
        </SendControls>
    </InputPanelStyles>
  )
}
