import React from 'react'
import { NETWORK_LABELS, NETWORK_LOGOS } from '../../constants/chains'
import styled from 'styled-components'

const NetworkLabel = styled.div`
  color: #807e7e;
  display: inline-block;
  //background: #2d3136; 
  //border: 1px solid #3c3c3c;
  border-radius: 5px;
  font-size: 12px; 
  min-width: 70px;
  text-align: center;
  height: 30px;
  border-radius: 7px;
  min-width: 100px;
  text-align: left;
  line-height: normal;
  padding: 3px;
`

interface Prop {
    chainId: number
}

export default function NetworkLabelComponent({chainId }: Prop) {
    if (NETWORK_LABELS[chainId]) {
        return <NetworkLabel>
                    <img style={{ width: "23px" }} src={NETWORK_LOGOS[chainId]} />
                    <div style={{ padding: "5px",paddingLeft: "8px", verticalAlign: "top",display: "inline-block" }}>{NETWORK_LABELS[chainId].toUpperCase()}</div>
                </NetworkLabel>
    }
    return <NetworkLabel>n/a</NetworkLabel>
}