import React from 'react'
import styled from 'styled-components/macro'

import SwapButton from './SwapButton.tsx'
import CurrencyLogo from '../../components/CurrencyLogo'

const ReceiveTableRow = styled.div`
  display: block;
  height: 78px;
  text-align: left;
`



const Cell = styled.div`
  vertical-align: top;
  display: inline-block;
  line-height: 32px;
`

const Logo = styled(Cell)`
  width: 32px;
  height: 32px;
  >img {
    width: 32px !important;
    height: 32px !important;
  }
  padding-top: 5px;
`

const Name = styled(Cell)`
  width: 60px;
  color: #CCCDCE;
  font-size: 18px;
  padding-left: 8px;
  padding-top: 5px;
`
const Balance = styled(Cell)`
  width: 166px;
  text-align: right;
  padding-right: 5px;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding-top: 5px;
`

const Ticker = styled(Cell)`
  width: 40px;
  color: #ccc;
  font-size: 14px;

`

export default function Component(index, coin, state, alert) {
    const value =
      state.data.taker.amount;
    return (
        <ReceiveTableRow key={"coin" + index}>
          <Logo>
            <CurrencyLogo currency={coin} />
          </Logo>
          <Name>{coin.text}</Name>
          <Balance>{value}</Balance>
          <Cell>
          {SwapButton(state, coin, alert)}
          </Cell>
        </ReceiveTableRow>
    )
}