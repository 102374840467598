import styled from 'styled-components';

export const Message = styled.div`
  display: block;
  margin-top: 15px;
  padding: 50px;
  opacity: 0.1;
  @media (max-width: 350px) {
    width: 100%
  }
  
`;
export const MessageText = styled.div`
  display: block;
  font-size: 20px;
  text-align: center;
  >div {
    display: inline-block;
    max-width: 150px;
  }
`;
export const MessageIcon = styled.img`
  width: 65px;
  margin: 15px;
`;
