export const updateQuery = (state: any) => {
    
    const makerChainId = state.data.maker.chainId
    if (makerChainId != null) {
        state.root.makerAddress = makerChainId.toString();
    }
    const takerCHainId = state.data.taker.chainId;
    
    if (takerCHainId != null) {
        state.root.makerAddress +=  "_" + takerCHainId.toString();
    }
    setImmediate(function() {
        location.hash = "#/" + state.root.page.toLowerCase() + "/" + (state.root.makerAddress ? state.root.makerAddress : "")
    })
    
};
