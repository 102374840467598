import React from 'react'
import styled from 'styled-components/macro'

import SearchInputArea from './SearchInputArea.tsx'

const SearchControls = styled.div`
  display: block;
`

export default function Component(allData) {
    return (
        <SearchControls>
          {SearchInputArea(allData)}
        </SearchControls>
    )
}