import React from 'react'
import styled from 'styled-components/macro'



const SearchList = styled.div`
  height: 200px;
  overflow-y: auto;
`
const SearchItem = styled.div`
  width: 95%;
  background: #272a2d;
  border-radius: 10px;
  margin: 10px;
  box-sizing: border-box;
  height: 60px;
  position: relative;
  cursor: pointer;
  >img
{
    width: 36px;
    height: 36px;
    position: absolute;
    left: 10px;
    top: 12px;
    >.Symbol, .Description
{
      padding-left: 60px;
      text-align: left;
}}
`
const SearchListSymbol = styled.div`
  padding-top: 5px;
  font-size: 20px;
`
const SearchListDescription = styled.div`
  color: gray;
  font-size: 12px;
`
const SearchListButton = styled.div`
  right: 10px;
  top: 15px;
  position: absolute;
  width: 30px;
  height: 30px;
  background: #303234;
  box-shadow: 9px 9px 16px rgba(0, 0, 0, 0.4), -9px -9px 16px rgba(73, 73, 73, 0.6);
  border-radius: 20px;
  >.chervon
{
    position: absolute;
    top: 7px;
    left: 12px;
    display: inline-block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAfCAYAAADqUJ2JAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAHwAAAABiOXbkAAABcklEQVRIDa3VzW2DMBQH8GdCRXNLD0EiJ4/QEcgG7QgdoRu0G3SDdoRuQEfoBuGGxCU5cii4728BIsUGG2opfBi/H7zwbARxO59Ou3q7fSeldhuip7skydHv0wIMrqMoZeSBD9OaKDsXhUS/T9NQE4Y5CXFpA+USTHR3LcvyPmiaDOm1fTmneXRNs4cQvAa7gtZgI2gppv9sBA9bHMffTRAc/74ApD4cNzw2QhjQY0R5GyDxMmyYMbU2UO8Krqkbri0+kbqDywRPixvp83YzC2GcC+YEuWDO0BzmBU1h3pANs75+BNjabVVdlFLdJCfMT+8nwtr1E0WZEKIvTkX06gXZkH2SvDhDWOywTnG6sksZTwIE506QEVHqeX84vHXoLGRCGl7X4yT56BDsJyFXZBLyQayQL2KEliAjaClyBa1BemiE8CrIc+mRi+0Lg1xaiEFcsSnvJP+4IMxLqb42sdGzf1NVnzxGf7ZN6/FE/P9f+gWJ5ORfQgouPAAAAABJRU5ErkJggg==);
    width: 8px;
    height: 13px;
    background-size: cover;
}
`

export default function Component(allData) {

    return (
      <SearchList>
        <div style={{maxWidth: "200px", display: "inline-block", color: "#827f7f"}}>
         There are no other options available yet
        </div>
      </SearchList>
    )

    return (
        <SearchList>
          <SearchItem><img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png" />
            <SearchListSymbol>BTC</SearchListSymbol>
            <SearchListDescription>Bitcoin</SearchListDescription>
            <SearchListButton>
              <div className="chervon"></div>
            </SearchListButton>
          </SearchItem>
        </SearchList>
    )
}