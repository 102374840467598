import React from 'react'
import styled from 'styled-components/macro'

import ModalOptionsPlaceholder from './ModalOptionsPlaceholder.tsx'
import ModalNetworksPlaceholder from './ModalNetworksPlaceholder.tsx'

const Modals = styled.div`

`

export default function Component(allData, alert) {
    return (
        <Modals>
          {ModalOptionsPlaceholder(allData, alert)}
          {ModalNetworksPlaceholder(allData, alert)}
        </Modals>
    )
}