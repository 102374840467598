import styled from 'styled-components/macro';

export const SendControls = styled.div`
  display: inline-block;
  background: linear-gradient(180deg, #24272B 0%, #272C30 100%);
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3), inset 0px 2px 3px rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  width: 100%;
  height: 48px;
  position: relative;
  text-align: right;
  ;
`;
