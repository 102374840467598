import { BigNumber } from 'ethers';
import Web3 from 'web3'

export function normalizeAmount (amount: string, decimals: string, recipientDecimals: string) {
  //const precision = 100000;

  const roundedAmount = recipientDecimals == "1" ? Math.floor(+amount).toString() : amount

  const largeNumber = Web3.utils.toWei(amount.toString(), 'ether')
  const rest = BigNumber.from("1000000000000000000").div(decimals.toString())
  //console.log("rest", rest.toString())
  const longRoundedAmount = BigNumber.from(largeNumber).div(rest)
  
  //const rest = BigNumber.from(decimals).div(precision).toString();
  //const longRoundedAmount = BigNumber.from(val).mul(rest).toString();
  //const roundedAmount = +val / precision
  return { longRoundedAmount, roundedAmount };
};