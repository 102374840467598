import React from 'react'
import styled from 'styled-components/macro'

import SendPlaceholderHeader from './SendPlaceholderHeader.tsx'
import SendControls from './SendControls.tsx'

const SendPlaceholder = styled.div`
    text-align: center;
`
const SendPlaceholderCenter = styled.div`
  width: 327px;
  display: inline-block;
`

export default function Component(allData: any, alert: any) {
    return (
        <SendPlaceholder>
          <SendPlaceholderCenter>
            {SendPlaceholderHeader(allData, alert)}
            {SendControls(allData, alert)}
          </SendPlaceholderCenter>
        </SendPlaceholder>
    )
}