import React from 'react'
import styled from 'styled-components/macro'



const ReceiveHeaderPart = styled.div`
  display: inline-block;
  width: 170px;
  box-sizing: border-box;
  color: #7F8489;
  font-size: 14px;
  &.Left
{
    text-align: left;
}
  &.Right
{
    text-align: right;
}
`

export default function Component(allData) {
    return (
        <ReceiveHeaderPart></ReceiveHeaderPart>
    )
}