import styled from 'styled-components'

const Collapsed = styled.div`
  border-radius: 18px;
  min-height: 50px;
  line-height: 50px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: relative;
  margin-bottom: 10px;
  background: linear-gradient(179deg,#2F353A 14.75%,#1C1F22 84.81%);
  filter: drop-shadow(0px 0px 1px #000);
  box-shadow: inset 0 3px 8px 1px rgb(31 27 27 / 50%), inset 0 1px 0 rgb(0 0 0 / 50%), 0 1px 0 rgb(255 255 255 / 20%);
`

export default Collapsed;
