const getNami = async (state)=> {

    if (state.data.nami.type == null)
        return null

    const nami_lib = await import('nami-wallet-api')
    const cardano = await window.cardano[state.data.nami.type].enable()
    

    if (cardano == null)
        return
    
    const Nami = await nami_lib.NamiWalletApi(cardano, state.data.apiKeys[state.data.maker.chainId])
    return Nami
}


export default getNami