import React from 'react'
import styled from 'styled-components/macro'
import Navigate from '../Navigate'


const SwitchTab = styled.div`
  z-index: 100;
  position: absolute;
  padding: 0 20px;
  z-index: 40;
  cursor: pointer;
  top: 0px;
  height: 55px;
  line-height: 55px;
  &.Swap
{
    left: 18px;
}
  &.Pool
{
    right: 23px;
}
`

export default function Component(state: any, page: string) {
    const switchPage = ()=> {

      if (state.data.taker.coin == null && page == "Pool")
        return


      Navigate(state, page)
    }
    return (
        <SwitchTab key={'switchtab' + page} className={page} onClick={switchPage}>{page}</SwitchTab>
    )
}