function getFee(amount, data) {
    //const fee = 0.04
    //2500
    
    if(data && data.maker && data.coins[data.maker.coin] && data.coins[data.maker.coin].lpFeePercent == "0") {
        return 0
    }

    if(data && data.maker && data.coins[data.maker.coin] && +data.coins[data.maker.coin].lpFeePercent > 0) {

        const fee = amount * +data.coins[data.maker.coin].lpFeePercent

        if (data.coins[data.taker.coin] && data.coins[data.taker.coin].decimals == "1") {
            return Math.ceil(fee)
        }

        return fee
    }

    return amount / 2500
}

export default getFee