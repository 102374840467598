import styled from 'styled-components'

const Active = styled.div`
  border-radius: 17px;
  background: linear-gradient(1deg,#2F353A 14.75%,#32373c 84.81%);
  padding: 17px;
  vertical-align: top;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 3px 8px 1px rgb(31 27 27 / 50%), 0 1px 0 rgb(0 0 0 / 50%), inset 0 1px 0 rgb(255 255 255 / 20%);
`

export default Active;
