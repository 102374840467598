import getLocalStorage from './getLocalStorage.js'
import moment from 'moment'
import { NETWORK_URLS } from '../../connectors'
import Web3 from 'web3'
import POOL_ABI from '../../abis/pool.json'
//import POOL_CARDANO_ABI from '../../abis/pool_cardano.json'
import ERC20_ABI from '../../abis/erc20.json'
//import { BigNumber } from "ethers";
import { makeHumanAmounts } from './makeHumanAmounts'
import blockfrostRequest from './blockfrostRequest'


async function startCheckMakerTransaction(data: any) {
    const ls = getLocalStorage(data);
    
    //ls.set({ stage: 2, takerTxStart: moment.utc() })
    //return true;


    const maker = data.coins[data.maker.coin];

    if (maker.type == "shelley_to_evm") {
        
        //success
        ls.set({ stage: 2, takerTxStart: moment.utc() })
        return true
    }
    
    const makerTx = data.process.makerTx;
    const makerWeb3 = new Web3(NETWORK_URLS[maker.chainId])
    const txinfo = await makerWeb3.eth.getTransactionReceipt(makerTx)
    
    if (txinfo == null) {
        ls.reset("Transaction is not found")
        return false
    }
    
    if (txinfo.status == true) {
        //console.log("txinfo", txinfo)
        if (data.process.takerTxStart == null) {
            ls.set({ stage: 2, takerTxStart: moment.utc() })
        }
        else {
            ls.set({ stage: 2 })
        }

        return true
    }

    if (txinfo.status == false) {
        //console.log("txinfo", txinfo)
        ls.reset("Transaction is failed")
        return true
    }

    return false;
    
}

async function startWaitMakerTransaction(data: any) {
    console.log("startWaitMakerTransaction", data.process.makerTxStart)
    const maker = data.coins[data.maker.coin];
    const diff = moment.utc().diff(moment.utc(data.process.makerTxStart), "seconds")
    //console.log("data.maker.confirmationPercent", data.maker.confirmationPercent)
    const confirmationPercent = Math.round(100 / (maker.averageConfirmationMin * 60) * diff)
    data.maker.confirmationPercent = confirmationPercent > 100 ? 100 : confirmationPercent
    
    return confirmationPercent >= 100;
}



async function startWaitTakerTranscation(data: any) {
    //console.log("startWaitTakerTranscation")

    const test = await startCheckTakerTransaction(data)

    if (test == true)
        return true

    
    const diff = moment.utc().diff(moment.utc(data.process.takerTxStart), "seconds")
    const waitMin = data.coins[data.taker.coin].averageReceiveMin
    const optimisticConfirmationSec = waitMin ? (+waitMin * 60) : 10
    const confirmationPercent = Math.round(100 / (optimisticConfirmationSec) * diff)
    data.taker.confirmationPercent = confirmationPercent > 100 ? 100 : confirmationPercent
    return confirmationPercent >= 100;
}

async function startCheckTakerTransaction(data: any) {

    const maker = data.coins[data.maker.coin];

    if (maker.type == "evm_to_shelley") {
        return startCheckTakerTransactionEvmToCardano(data)
    }
    else if (maker.type == "shelley_to_evm") {
        return startCheckTakerTransactionEvm(data)
    }
    else {
        return startCheckTakerTransactionEvm(data)
    }

}



async function startCheckTakerTransactionEvmToCardano(data: any) {

    if (data.process.stage == 0) 
        return

    const address = data.taker.recipient
    //const asset = data.coins[data.maker.coin].unit
    //https://cardano-mainnet.blockfrost.io/api/v0/addresses/{address}/transactions
    const from_block = data.process.makerBlockNumber
    //const to_block = ""
    const transactions = await blockfrostRequest(data, `/addresses/${address}/transactions?from=${from_block}`)

    //alert("startCheckTakerTransactionEvmToCardano")
    if (transactions == null) {
        return false
    }

    if (transactions.length > 0) {

        const makerTx = data.process.makerTx;

        const ls = getLocalStorage(data);
        ls.set({ stage: 3 })
        return true

    }

    return false


}



async function startCheckTakerTransactionEvm(data: any) {
    if (data.process.stage > 2)
        return true
    //if (data.process.stage != 2) 
    //    return
    const taker = data.coins[data.taker.coin];
    const ls = getLocalStorage(data);

    

    //ls.set({ stage: 3, takerTx: "fakeTX" })
    //return true

    const makerTx = data.process.makerTx;

    //exit from case when use got the result
    if (makerTx == null)
        return true

    const takerWeb3 = new Web3(NETWORK_URLS[taker.chainId])
    const takerContract = new takerWeb3.eth.Contract(POOL_ABI, taker.swapAddress)
    console.log("knownForainTxs", taker.swapAddress, makerTx)
    const known = await takerContract.methods.knownForainTxs(makerTx).call()
    console.log("known", known)

    
    if(known == false) {
        return false
    }
    else {

        if (data.account != null) {
            
            if (taker.tokenAddress == "native") {
                const takerBalance = await takerWeb3.eth.getBalance(data.account)
                data.taker.balance = makeHumanAmounts(takerBalance, taker.decimals)
            }
            else {
                const takerTokenContract = new takerWeb3.eth.Contract(ERC20_ABI, taker.tokenAddress)
                const takerBalance = await takerTokenContract.methods.balanceOf(data.account).call();
                //console.log("new balance")
                data.taker.balance = makeHumanAmounts(takerBalance, taker.decimals)
            }

        }

        
        ls.set({ stage: 3 })
        return true
    }
}

const process = [
    startWaitMakerTransaction,
    startCheckMakerTransaction,
    startWaitTakerTranscation,
    startCheckTakerTransaction
]

async function processOneByOne (data: any, index: number) {
    console.log("process index", index)
    if (data.process.stage == 0) 
        return
    const func = process[index];

    if (typeof func != 'function')
        return

    const result = await func(data)
    console.log("result", result, "for index", index)
    
    if (result == true)
        processOneByOne(data, index + 1)
    else {
        setTimeout(function() { processOneByOne(data, index) }, 5000)
    }
}

const ensureProcess = (data: any)=> {
    if (data.process.stage > 0 ) {
        startProcess(data)
    }
}

const startProcess = (data: any)=> {
    if (data.process.stage < 3)
        processOneByOne(data, 0);
}

async function swapRequest(data: any, hash: string) {
    const maker = data.coins[data.maker.coin];
    const taker = data.coins[data.taker.coin];
    const ls = getLocalStorage(data);

    if (maker.type == "shelley_to_evm") {
        ls.set({ stage: 1, makerTx: hash, makerTxStart: moment.utc(), maker_amount: data.maker.amount })

    } else if ( maker.type == "evm_to_shelley" ) {
        //const makerWeb3 = new Web3(NETWORK_URLS[maker.chainId])
        //const makerblockNumber = await makerWeb3.eth.getBlockNumber()
        
        const block = await blockfrostRequest(data, "/blocks/latest")

        

        if (block) {
        
            ls.set({ stage: 1, makerTx: hash, makerTxStart: moment.utc(), makerBlockNumber: block.height.toString(), maker_amount: data.maker.amount })
        
        }
        else {
            alert("block is not available")
        }

    }
    else {
        const makerWeb3 = new Web3(NETWORK_URLS[maker.chainId])
        const makerblockNumber = await makerWeb3.eth.getBlockNumber()
        const takerWeb3 = new Web3(NETWORK_URLS[taker.chainId])
        const takerblockNumber = await takerWeb3.eth.getBlockNumber()
        
        ls.set({ stage: 1, makerTx: hash, makerTxStart: moment.utc(), makerBlockNumber: makerblockNumber.toString(), takerBlockNumber: takerblockNumber.toString(), maker_amount: data.maker.amount })
        
    }

    startProcess(data);
    
}

export default { swapRequest, ensureProcess }


