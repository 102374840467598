import ls from 'local-storage'

const makeGet = (key)=> ()=> {
    const data = ls.get(key)
    if (data) {
        return JSON.parse(data)
    }
    return { stage: 0, makerTx: '', takerTx: '' }
}

const makeSyncWithStore = (data, key)=> ()=>{
    const currentData = makeGet(key)()
    data.process = currentData
}

const makeSet = (key, data) => (value)=> {
    const currentData = makeGet(key)()
    const newValue = JSON.stringify({ ...currentData, ...value })
    ls.set(key, newValue)
    makeSyncWithStore(data, key)()
}

const makeReset = (key, data)=> (error)=> {
    const newStore = {
        stage: 0,
        error: error
    }
    ls.set(key, JSON.stringify(newStore))
    makeSyncWithStore(data, key)()
}

const getLocalStorage = (data)=> {
    const taker = data.coins[data.taker.coin];
    const maker = data.coins[data.maker.coin];
    const key = data.account + maker.swapAddress + taker.swapAddress
    const get = makeGet(key)
    const set = makeSet(key, data)
    const syncWithStore = makeSyncWithStore(data, key)
    const reset = makeReset(key, data)
    return { get, set, syncWithStore, reset }
}

export default getLocalStorage;