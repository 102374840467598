import React from 'react'
import styled from 'styled-components/macro'

import NetworkBox from './NetworkBox.tsx'
import NetworkChevron from './NetworkChevron.tsx'

const NetworkBoxes = styled.div`
  display: inline-block;
  margin-bottom: 50px;
  width: 100%;
`

export default function Component(state, alert) {
    return (
        <NetworkBoxes>
          {NetworkBox(state.data.maker.chainId, "Left", state, alert)}
          {NetworkChevron()}
          {NetworkBox(state.data.taker.chainId, "Right", state, alert)}
        </NetworkBoxes>
    )
}