import React from 'react'
import { Loading } from './Loading'
import { ButtonPrimary } from '../../components/Button'
import { Trans } from '@lingui/macro'
import { isAllowed } from './isAllowed'

export default function Component(data: any, action: func) {

    if (data.maker.coin == null || data.loadingButton == "approve") {
      return (<ButtonPrimary style={{ marginBottom: '10px', background: '#b838c5' }}>
          <Loading></Loading>
      </ButtonPrimary>)
    }
    else if (!isAllowed(data))
      return (
        <ButtonPrimary onClick={action} style={{ marginBottom: '10px', background: '#b838c5' }}>
          <Trans>Approve To Join</Trans>
        </ButtonPrimary>
      )
    return null
}