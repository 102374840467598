import React from 'react'
import styled from 'styled-components/macro'

import ReceiveHeader from './ReceiveHeader.tsx'
import ReceiveTable from './ReceiveTable.tsx'

const ReceivePlaceholder = styled.div`
  margin: 0 auto;
`

export default function Component(state, alert) {
    return (
        <ReceivePlaceholder>
          {ReceiveHeader(state, alert)}
          {ReceiveTable(state, alert)}
        </ReceivePlaceholder>
    )
}