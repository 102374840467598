import React from 'react'
import styled from 'styled-components/macro'

import ModalOptionsContainer from './ModalOptionsContainer.tsx'

const ModalOptionsPlaceholder = styled.div`
  position: fixed;
  z-index: 1000;
  top: 460px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
  &.active
{
  opacity: 1;
  visibility: visible;
}
  width: 100%;
  text-align: center;
`

export default function Component(allData) {
  const className = allData.data.expandOptionsList ? "active" : ""  
  return (
        <ModalOptionsPlaceholder className={className}>
          {ModalOptionsContainer(allData)}
        </ModalOptionsPlaceholder>
    )
}