import { Stats } from 'fs'
import React from 'react'
import styled from 'styled-components/macro'
import refreshSwapInfo from '../SwapInfo/refreshSwapInfo'
import { updateQuery } from '../updateQuery'
import decors from './decors.tsx'
import Chevron from 'react-chevron'

const SwapButton = styled.div`
  z-index: 1;
  margin-top: -1px;
  width: auto;
  height: 43px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: right;
  >.decor1
{
    position: absolute;
    top: 0.3px;
    left: 0.63px;
    width: 79.55px;
    height: 42.33px;
    background: linear-gradient(318.21deg, #11A8FD 14.05%, #0081C9 85.29%);
    box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.8);
    border-radius: 100px;
    transform: rotate(-180deg);
}
  >.decor2
{
    display: none;
    position: absolute;
    width: 80.82px;
    height: 43px;
    top: 0;
    left: -1px;
    background: linear-gradient(320.66deg, #11A8FD 14.75%, #0081C9 84.81%);
    filter: blur(1px);
    border-radius: 100px;
    transform: rotate(-180deg);
}
  >.decor3
{
    position: absolute;
    width: 75.77px;
    height: 40.31px;
    top: 1.34px;
    left: 2.5px;
    background: linear-gradient(320.66deg, #005EA3 14.75%, #11A8FD 84.81%);
    border: 1px solid #058DD9;
    box-sizing: border-box;
    border-radius: 100px;
    transform: rotate(-180deg);
}
  >.decor4
{
    position: absolute;
    width: 75.77px;
    height: 40.31px;
    top: 1.34px;
    left: 2.5px;
    background: linear-gradient(320.66deg, #016BB8 14.75%, #11A8FD 84.81%);
    filter: blur(1px);
    border-radius: 100px;
    transform: rotate(-180deg);
}
`
const SwapButtonText = styled.div`
  position: absolute;
  width: 100%;
  z-index: 10;
  text-align: center;
  height: 43px;
  line-height: 43px;
`

export default function Component(state: any, coin: any, alert: any) {
    
    return (
        <SwapButton>
          <SwapButtonText><Chevron /></SwapButtonText>
        </SwapButton>
    )
}