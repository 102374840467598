import React from 'react'
import styled from 'styled-components/macro'

import SearchHeader from './SearchHeader.tsx'
import SearchControls from './SearchControls.tsx'
import SearchNetwork from './SearchNetwork.tsx'
import SearchList from './SearchList.tsx'

const ModalOptionsContainer = styled.div`
  background: linear-gradient(#191b1f, #1e2125);
  box-shadow: 9px 9px 16px rgb(0 0 0 / 34%), -9px -9px 16px rgb(73 73 73 / 26%);
  width: 380px;
  @media (screen and max-width: 500px)
{
    width: 100%;
}
  height: inherit;
  max-height: 90vh;
  vertical-align: bottom;
  display: inline-block;
  border-radius: 25px;
`

export default function Component(allData) {
    return (
        <ModalOptionsContainer>
          {SearchHeader(allData)}
          {SearchControls(allData)}
          {SearchNetwork(allData)}
          {SearchList(allData)}
        </ModalOptionsContainer>
    )
}