import { Trans } from '@lingui/macro'
import React from 'react'
import { ButtonPrimary } from '../../components/Button'
//import callBlockchain from '../SwapInfo/callBlockchain.js'
import processManager from '../SwapInfo/processManager.ts'
import Web3 from 'web3'
import { normalizeAmount } from './normalizeAmount'
import { Loading } from '../PoolForm/Loading'
import { BigNumber } from 'ethers'
import getNami from '../SwapInfo/getNami'
import blockfrostRequest from '../SwapInfo/blockfrostRequest'
import { Address } from '@emurgo/cardano-serialization-lib-asmjs'
import { isAllowed } from '../PoolForm/isAllowed'



const checkAddress = (address)=> {
  //return (address.indexOf('addr1') == 0 || address.indexOf('addr_test1') == 0)
  try {
    Address.from_bech32(address)
    return true
  }
  catch(err) {
    return false
  }
}

const waitCardanoTransaction = async (data, hash, limit, resolve, reject)=>  {

  if (limit < 0) {
    return reject("Transaction Timeout")
  }

  const txinfo = await blockfrostRequest(data, `/txs/${hash}`)
  //console.log("txinfo", txinfo)

  if (txinfo != null && txinfo.status_code != 404) {
    console.log("txinfo", txinfo)
    return resolve(txinfo)
  }

  setTimeout(()=> {

    waitCardanoTransaction(data, hash, limit - 1, resolve, reject);

  }, 30000)


}


const getUnit = (unit: string)=> {

  const parts = unit.split(".");

  if (parts.length == 1) {
    return unit;
  } 

  return parts[0] + Web3.utils.toHex(parts[1]).replace('0x', '')

}


function valueToHex(chainId: any) {
  return Web3.utils.padLeft(Web3.utils.toHex(chainId), 8);
}

function assetToAssetId(asset: any){
  
  const chainId = valueToHex(asset.chainId); /* Web3.utils.padLeft(Web3.utils.toHex(asset.chainId), 8); */
  const symbol = valueToHex(asset.symbol).replace("0x", "");
  return chainId + symbol + asset.address.replace("0x", "");
};



const makeSwapRequest = async (state: any, swapContract: any)=> {

    const data = state.data;

    const { longRoundedAmount, roundedAmount } = normalizeAmount(data.maker.amount, data.coins[data.maker.coin].decimals, data.coins[data.taker.coin].decimals)

    data.maker.amount = roundedAmount

    const minAmount = data.coins[data.maker.coin].minAmount;

    if (minAmount) {
      
      if (+data.maker.amount < +minAmount) {
        return Promise.reject(`Minumum amount is ${minAmount} ${data.coins[data.maker.coin].text}`)
      }

    }


    const crosschain =
        Web3.utils.toWei(data.maker.fee.Crosschain, "ether")
      
    const value = 
        data.coins[data.maker.coin].tokenAddress == "native" ? BigNumber.from(crosschain).add(longRoundedAmount).toString() : crosschain

    const maker = state.data.coins[state.data.maker.coin]
    
    const referral = Web3.utils.isAddress(state.data.maker.referral) ? state.data.maker.referral : 
      (data.coins[data.maker.coin].buybackAndBurn || "0x0000000000000000000000000000000000000000")
    //alert(referral);
    //console.log("referral", data.coins[data.maker.coin].buybackAndBurn);
    //console.log("referral", referral);

    if (maker.type == "evm_to_shelley") {
      
      if (checkAddress(data.taker.recipient) == false) {
        return Promise.reject("please enter correct recipient address")
      }
      
      const recipient = Web3.utils.toHex(data.taker.recipient)
      //console.log("request", maker.tokenAddress, longRoundedAmount.toString(), recipient);
      

      return swapContract
      .swapRequest(maker.tokenAddress, longRoundedAmount, recipient, {
        gasLimit: 280000,
        value: value
      })
    }
    else if (maker.type == "shelley_to_evm") {

      if (maker.unit == null) {
        return Promise.reject("Unit is required")
      }

      if (!Web3.utils.isAddress(state.data.taker.recipient)) {
        return Promise.reject("Please enter valid recipient address")
      }

      /* race protection */


      

      if (maker.unit == null) {
        return Promise.reject("Required Maker Unit")
      }

      /* race protection */


      const isLovelace = maker.unit == "lovelace"
      const amount = isLovelace  ? state.data.maker.amount : "1.4";
      const assets = isLovelace  ? [] : [{ "unit": maker.unit, "quantity": longRoundedAmount.toString() }]

      //console.log("da8c30857834c6ae7203935b89278c532b3995245295456f993e1d24.LQ" === maker.unit)

      const request = {
        address: maker.swapAddress,
        amount: amount,
        metadataLabel: "674",
        assets: assets,
        metadata: {
            msg: [state.data.taker.recipient]
        }
      }

      const nami = await getNami(state)
      
      if (nami == null) {
        return Promise.reject("Nami wallet is not connected")
      }
      
      if (maker != state.data.coins[state.data.maker.coin]) {
        return Promise.reject("Wrong Maker")
      }

      const hashTx = await nami.send(request)

      const hash = Web3.utils.toHex(hashTx + getUnit(maker.unit))

            
      const wait = async ()=> {

        return new Promise((resolve, reject) => {
          waitCardanoTransaction(state.data, hashTx, 241, resolve, reject)
        }
        )
      }

      return Promise.resolve( { hash, wait } )

    }
    else if (maker.type == "multichain_wrap") {
      const tokenAddress = data.coins[data.maker.coin].tokenAddress  == "native" ? '0x0000000000000000000000000000000000000000' : data.coins[data.maker.coin].tokenAddress
      return swapContract
      .swapRequestNative(longRoundedAmount, tokenAddress, valueToHex(data.taker.chainId), referral, {
        gasLimit: 180000,
        value: value
      })
    }
    else if (maker.type == "multichain_unwrap") {
      //const assetId = assetToAssetId({ chainId: data.coins[data.taker.coin].chainId, symbol: data.coins[data.taker.coin].name, address: data.coins[data.taker.coin].tokenAddress  });

      return swapContract
      .swapRequestWrapped(data.coins[data.maker.coin].tokenAddress,longRoundedAmount, referral, {
        gasLimit: 180000,
        value: value
    })
    }
    else {
      return swapContract
      .swapRequest(longRoundedAmount, data.coins[data.taker.coin].chainId, data.coins[data.taker.coin].assetId, {
        gasLimit: 180000,
        value: value
      })
    }

}

const swap = (state: any, swapContract: any, alert: any)=> ()=> {
      
  const data = state.data;

  const taker = data.coins[data.taker.coin];
  const maker = data.coins[data.maker.coin];

  if (maker.doNotAllowToSwap == true) {
    alert.info("This direction is disabled");
    return
  }



  //return alert("Swapz is temporary paused due to bsc chain issues")

  //const taker = data.coins[data.taker.coin];

  const notEnoughBalance = +data.maker.balance < +data.maker.amount
  
  if (+data.maker.amount == 0) return

  if (notEnoughBalance) return

  data.loadingButton = "swap"
  if (taker.chainId == null) {
      alert.info("Chain is null")
      return
  }

  //if (swapContract == null) {
  //  alert('Token Contract is null')
  //  return
  //}
  
  makeSwapRequest(state, swapContract)
  .then((response:any) => {
    if (response.hash) {
      response
        .wait(1)
        .then((x:any)=> {
          processManager.swapRequest(data, response.hash)
          data.loadingButton = null
        })
        .catch((x:any)=> {
          data.loadingButton = null
          alert.info("" + x)
        })
    }
  })
  .catch((error: Error) => {
    data.loadingButton = null

    if (error && error.message) {
        alert.info("" + error.message)
    }
    else {
      alert.info("" + error)
    }

    
  })
}

const renderSwapButton = (state: any, swapContract: any, alert: any) => {

    const data = state.data;
    const taker = data.coins[data.taker.coin];

    const type = (!(taker.type)) ? "limited" : "unlimited"

    const disabled = taker.disabled;

    if (disabled == true)
      return alert.info("This direction is closed right now");

    const notEnoughBalance = +data.maker.balance < +data.maker.amount
    const notEnoughLiquidity = taker.type == "limited" && +data.taker.balancePool - +data.taker.amount < 0

    if (data.maker.coin == null || data.loadingButton == "swap") {
      return (<ButtonPrimary>
          <Loading></Loading>
      </ButtonPrimary>)
    }
    else if (data.coins[data.maker.coin].tokenAddress != "native" && !isAllowed(data)
    ) {
      return null
    }
    else if (+data.taker.amountEth < 0.1) {
      return null
    }
    else if (notEnoughBalance) {
      return (<div style={{ textAlign: "center", color: "red" }}>Your balance is {data.maker.balance} {data.coins[data.maker.coin].text}. Your swap amount is {data.maker.amount} {data.coins[data.maker.coin].text} </div>)
    }
    else if (taker.maxAmount && +taker.maxAmount < +data.maker.amount) {
      //taker.maxAmount : data.maker.amount;
      //console.log("swapAmount", swapAmount)
      return (<div style={{ textAlign: "center", color: "orange" }}>Maximum Amount is {taker.maxAmount} {data.coins[data.maker.coin].text}</div>)
    }
    
    //else if (true) {
    //  return null
    //}
    //else if (+data.taker.amount > 2000) {
    //  return null
    //}
    else if (notEnoughLiquidity) {
      return null
    }
    else if (data.process.stage == 0)
      return (
        <ButtonPrimary onClick={swap(state, swapContract, alert)}>
          <Trans>Swap</Trans>
        </ButtonPrimary>
      )
    return null
  }

export default renderSwapButton;