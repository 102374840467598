import React from 'react'
import styled from 'styled-components/macro'

import ReceiveTableRow from './ReceiveTableRow.tsx'

const ReceiveTable = styled.div`
  display: inline-block;
  width: 339px;
  padding-top: 10px;
`

export default function Component(state: any, alert: any) {
    return null


    const d = state.data

    if (d.taker.chainId == null)
      return null
    const coins = d.coins.filter((c: any)=> c.chainId == d.taker.chainId && c.group == d.coins[d.maker.coin].group)
    
    if (coins.length > 0) {
      return (
          <ReceiveTable>
            {coins.map((value, index) => {
                return ReceiveTableRow(index, value, state, alert)
            })}
          </ReceiveTable>   
      )
    } else {
      return ( <div style={{ paddingBottom: "40px", color: "gray" }}>There are no options for this Network</div>)
    }
}