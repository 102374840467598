import React from 'react'
import Collapsed from './components/Collapsed'
import CollapsedNumber from './components/CollapsedNumber'
import ProgressBar from "@ramonak/react-progress-bar";
import cutTx from './cutTx'
import { NETWORK_LABELS } from '../../constants/chains'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import Active from './components/Active'

const renderConfirmation = ({data, account, chainId })=> {
  if (data.process.stage == 0) {
    return (
      <Collapsed>
        <CollapsedNumber>2</CollapsedNumber>
        <span>Finality: About {data.coins[data.taker.coin].averageConfirmationMin} min</span>
      </Collapsed>
    );
  }

  if (data.process.stage == 1) {
    return (
      <Active>
          <div style={{ display: "inline-block", width: "100%", verticalAlign: "top" }}>
            <div style={{fontWeight: "bold", marginBottom: "5px"}}>Finality</div>
            <div style={{paddingBottom: '9px', fontSize: "13px"}}>Transaction ({cutTx(data.process.makerTx)}) is submitted on chain {NETWORK_LABELS[data.coins[data.maker.coin].chainId]}. Waiting for the finality</div>
            <ProgressBar labelAlignment="center" completed={data.maker.confirmationPercent} height={15} bgColor={'rgb(140 73 175)'} baseBgColor={'rgb(36 40 44)'} />
          </div>
      </Active>
    );
  }

  if (data.process.stage > 1) {
    return (
      <Collapsed>
        <CollapsedNumber>2</CollapsedNumber>
          Transaction (<a target="blank" href={getExplorerLink(chainId, data.process.makerTx, ExplorerDataType.TRANSACTION)}>{cutTx(data.process.makerTx)}</a>) is finalized
      </Collapsed>
    );
  }

}

export default renderConfirmation;