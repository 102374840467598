import React from 'react'
import styled from 'styled-components/macro'



const SearchButtonHide = styled.div`
  height: 40px;
  line-height: 40px;
  cursor: pointer;
`

const SearchHeader = styled.div`
  height: 40px;
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAfCAYAAADqUJ2JAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAHwAAAABiOXbkAAABcklEQVRIDa3VzW2DMBQH8GdCRXNLD0EiJ4/QEcgG7QgdoRu0G3SDdoRuQEfoBuGGxCU5cii4728BIsUGG2opfBi/H7zwbARxO59Ou3q7fSeldhuip7skydHv0wIMrqMoZeSBD9OaKDsXhUS/T9NQE4Y5CXFpA+USTHR3LcvyPmiaDOm1fTmneXRNs4cQvAa7gtZgI2gppv9sBA9bHMffTRAc/74ApD4cNzw2QhjQY0R5GyDxMmyYMbU2UO8Krqkbri0+kbqDywRPixvp83YzC2GcC+YEuWDO0BzmBU1h3pANs75+BNjabVVdlFLdJCfMT+8nwtr1E0WZEKIvTkX06gXZkH2SvDhDWOywTnG6sksZTwIE506QEVHqeX84vHXoLGRCGl7X4yT56BDsJyFXZBLyQayQL2KEliAjaClyBa1BemiE8CrIc+mRi+0Lg1xaiEFcsSnvJP+4IMxLqb42sdGzf1NVnzxGf7ZN6/FE/P9f+gWJ5ORfQgouPAAAAABJRU5ErkJggg==);
  width: 8px;
  height: 13px;
  background-size: cover;
  transform: rotate(90deg);
`

export default function Component(allData) {
    const collapse = ()=> {
      allData.data.expandOptionsList = null;
    }
    return (
        <SearchButtonHide>
          <SearchHeader onClick={collapse}></SearchHeader>
        </SearchButtonHide>
    )
}