export default async function blockfrostRequest(data: any, endpoint : string) : Promise<any>{

    const chainId = data.maker.chainId.toString().indexOf("cardano") == 0 ? data.maker.chainId : data.taker.chainId

    const networkEndpoint = chainId == "cardano:0" ? 
        'https://cardano-testnet.blockfrost.io/api/v0' 
        : 
        'https://cardano-mainnet.blockfrost.io/api/v0'
    try {
        return await (await fetch(`${networkEndpoint}${endpoint}`,{
            headers: {
                project_id: data.apiKeys[chainId]
            }
        })).json()
      } catch (error) {
        return null
    }
}