import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { SupportedChainId } from '../constants/chains'
import getLibrary from '../utils/getLibrary'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import UNISWAP_LOGO_URL from '../assets/svg/logo.svg'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
const WALLETCONNECT_BRIDGE_URL = process.env.REACT_APP_WALLETCONNECT_BRIDGE_URL

if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

export const NETWORK_URLS: {
  [chainId in SupportedChainId]: string
} = {
  [SupportedChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.GOERLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.ARBITRUM_KOVAN]: `https://kovan5.arbitrum.io/rpc`,
  [SupportedChainId.ARBITRUM_ONE]: `https://arb1.arbitrum.io/rpc`,
  [SupportedChainId.BINANCE_SMART_CHAIN]: `https://bsc-dataseed1.defibit.io/`,
  [SupportedChainId.BINANCE_SMART_CHAIN_TESTNET]: `https://data-seed-prebsc-1-s1.binance.org:8545/`,
  [SupportedChainId.VELAS_TESTNET]: `https://testnet.velas.com/rpc`, 
  [SupportedChainId.VELAS]: `https://mainnet.velas.com/rpc`, 
  [SupportedChainId.POLYGON_TESTNET]: `https://rpc-mumbai.matic.today`, 
  [SupportedChainId.POLYGON]: `https://rpc-mainnet.maticvigil.com`,
  [SupportedChainId.FANTOM]: `https://rpc.ftm.tools/`, 
  [SupportedChainId.AVALANCHE]: `https://api.avax.network/ext/bc/C/rpc`,
  [SupportedChainId.TELOS]: `https://rpc1.us.telos.net/evm`,
  [SupportedChainId.NEON]: `https://neon-proxy-mainnet.solana.p2p.org`,
}

const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.MAINNET,
  SupportedChainId.KOVAN,
  SupportedChainId.GOERLI,
  SupportedChainId.RINKEBY,
  SupportedChainId.ROPSTEN,
  SupportedChainId.ARBITRUM_KOVAN,
  SupportedChainId.BINANCE_SMART_CHAIN,
  SupportedChainId.BINANCE_SMART_CHAIN_TESTNET,
  SupportedChainId.VELAS_TESTNET,
  SupportedChainId.VELAS,
  SupportedChainId.POLYGON_TESTNET,
  SupportedChainId.POLYGON,
  SupportedChainId.FANTOM,
  SupportedChainId.AVALANCHE,
  SupportedChainId.TELOS,
  SupportedChainId.NEON
]

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: 56, //56
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
  rpc: NETWORK_URLS,
  bridge: WALLETCONNECT_BRIDGE_URL,
  qrcode: true,
  pollingInterval: 15000,
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1,
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1],
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URLS[1],
  appName: 'Swapz',
  appLogoUrl: UNISWAP_LOGO_URL,
})
