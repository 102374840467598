//import { SupportedChainId } from '../constants/chains'
//import updateQuery from './updateQuery'

function resetSwapInfo(state) {

    const data = state.data;
    const chainId = state.data.maker.chainId;

    const supportedMakerCoins = data.coins.filter((c)=> c.chainId == chainId)

    if (supportedMakerCoins.length == 0) {
        return
    }

    if (data.maker.coin == null || (data.coins[data.maker.coin] && data.coins[data.maker.coin].chainId != chainId)) {
        data.maker.coin = data.coins.indexOf(supportedMakerCoins[0]);
    }
        

    

    //if (chainId == data.taker.chainId) {
    //    data.taker.chainId = data.coins.find((c)=> c.chainId != chainId).chainId
    //}

    //if (chainId)
    const maker = data.coins[data.maker.coin]
    //const taker = data.coins.find((c)=> c.chainId == data.taker.chainId)

    if (data.taker.chainId == data.maker.chainId) {
        const possibleTaker = data.coins.find((c)=> c.chainId != chainId && c.group == maker.group)
        if (possibleTaker) {
            data.taker.chainId = possibleTaker.chainId
        }
        else {
            alert("Taker Coin cannot be found for group - " + maker.group)
        }
    }

    

    data.process.stage = 0;
    data.maker.amount = 0;
    data.taker.amount = 0;
    
    //data.taker.chainId = SupportedChainId.ROPSTEN;
}

export default resetSwapInfo