import React from 'react'
import styled from 'styled-components/macro'
//import * as IPFS from 'ipfs-core'
import { Loading } from '../PoolForm/Loading'

const Button = styled.a`
    font-size: 16px;
    font-variant: none;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
    --reach-dialog: 1;
    box-sizing: border-box;
    font-family: 'Inter var', sans-serif;
    color: #c78409;
    text-decoration: none;
    text-align: center;
    display: block;
    padding: 12px;
    border-radius: 26px;
    border: 1px solid #704c09;
    margin: 10px 0;
    background: #34322f;
    cursor: pointer;
`

const mapping = {
    1 : "ethereum",
    56 : "bsc",
    106 : "velas",
    137 : "polygon"
}

const getDirection = (data)=> {
    //data.process.makerTx = "0x94a7c21744a4fe79ffea3811af72c79de62a40b862fdf52d12597746f006cdbf"
    return mapping[data.coins[data.maker.coin].chainId] + "_" + data.coins[data.maker.coin].text + "__" + mapping[data.coins[data.taker.coin].chainId] + "_" + data.coins[data.maker.coin].text + "__" + data.process.makerTx
}

export default function ContactSupport({ data }: any) {
    const makeLink = async ()=> {
        data.loadingButton = "support"
        /*
        try {
            
            const ipfs = await IPFS.create()
            const direction = getDirection(data)
            const { cid } = await ipfs.add(direction)
            data.loadingButton = null
            const bot = data.coins[data.maker.coin].support
            const link = `https://t.me/${bot}?start=${cid}` 
            window.open(link);
        }
        catch (err) {
            data.loadingButton = null
            window.open("https://t.me/joinchat/KGFAzfYLsdRkMjgy");
        }
        */
       window.open("https://t.me/joinchat/KGFAzfYLsdRkMjgy");
    }

    return (<Button style={{textAlign: "center"}} onClick={makeLink}>
        { data.loadingButton == "support" ? <Loading /> : <span>Resolve Issue</span>}</Button>)
}
