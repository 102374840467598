import styled from 'styled-components'

const CollapsedNumber = styled.div`
  position: absolute;
  top: 0px;
  left: 0px; 
  width: 50px; 
  height: 100%; 
  z-index: 2; 
  text-align: center;
  border-right: 1px solid #212428;
`

export default CollapsedNumber;

