import React from 'react'
import styled from 'styled-components/macro'





export default function Component() {
    return (
        <>
            <div className="decor1"></div>
            <div className="decor2"></div>
            <div className="decor3"></div>
            <div className="decor4"></div>
            <div className="decor5"></div>
        </>
    )
}