import React from 'react'
import styled from 'styled-components/macro'

import ReceiveHeaderPart from './ReceiveHeaderPart.tsx'

const ReceiveHeader = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  padding-top: 50px;
`

export default function Component(allData) {
    return (
        <ReceiveHeader>
          {ReceiveHeaderPart("Left", "Receive", allData)}
          {ReceiveHeaderPart("Right", "Total Pairs", allData)}
        </ReceiveHeader>
    )
}