import React from 'react'
import styled from 'styled-components/macro'



const SearchNetwork = styled.div`
  text-align: left;
  color: gray;
  font-size: 12px;
  padding-top: 30px;
  padding-left: 18px;
  box-sizing: border-box;
  height: 40px;
`

export default function Component(allData) {
    return (
        <SearchNetwork>Options:</SearchNetwork>
    )
}