import React from 'react'
import styled from 'styled-components/macro'

import ModalNetworkList from './ModalNetworkList.tsx'
import ModalNetworksHide from './ModalNetworksHide.tsx'




const ModalNetworksContainer = styled.div`
  display: inline-block;
  background: black;
  box-shadow: 9px 9px 16px rgba(0, 0, 0, 0.4), -9px -9px 16px rgba(73, 73, 73, 0.6);
  width: 400px;
  height: inherit;
  background: linear-gradient(180deg, #353A40 -56.47%, #16171B 100%);
  border-radius: 0 0 50px 50px;
  @media (max-width: 700px)
  {
    width: 100%;
    border-radius: 0 0 10px 10px;
    box-shadow: none;
  }
`

export default function Component(allData, alert) {
    return (
        <ModalNetworksContainer>
          {ModalNetworkList(allData, alert)}
          {ModalNetworksHide(allData, alert)}
        </ModalNetworksContainer>
    )
}