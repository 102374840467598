import Web3 from 'web3'
import { NETWORK_URLS } from '../../connectors'
import ERC20_ABI from '../../abis/erc20.json'
import POOL_ABI from '../../abis/pool.json'
//import POOL_ABI from '../../abis/pool.json'
import { transaction } from 'mobx'
import resetSwapInfo from '../resetSwapinfo'
import { makeHumanAmounts } from '../SwapInfo/makeHumanAmounts'
import { getBalanceInfo } from '../SwapInfo/getTokenInfo'

import { BigNumber } from "ethers";
//import { constantToCode } from 'multicodec/src/maps'

async function getTakerBalancePool (data, data_maker_balancePool) {

  console.log("get", data.maker.chainId, data.taker.chainIdPool, data.maker.assetId, data.taker.assetId)
  if (data.maker.chainId == data.taker.chainIdPool && data.maker.assetId == data.taker.assetId) {
    return data_maker_balancePool
  }

  const takerWeb3 = new Web3(NETWORK_URLS[data.taker.chainIdPool]);
  const taker = data.coins[data.taker.coin];
  console.log("taker 1", taker.text);

  if (taker.tokenAddress == "native") {
    console.log("native")
    const takerPoolBalance = await takerWeb3.eth.getBalance(taker.swapAddress)
    return BigNumber.from(takerPoolBalance).div(taker.decimals)
  }

  const tokenContract = new takerWeb3.eth.Contract(ERC20_ABI, taker.tokenAddress);
  console.log("takerPoolBalance 1", taker.tokenAddress, taker.swapAddress);
  const takerPoolBalance = await tokenContract.methods.balanceOf(taker.swapAddress).call();
  console.log("takerPoolBalance 2", taker.text);
  return BigNumber.from(takerPoolBalance).div(taker.decimals)

}

async function getTakerBalanceEth (data) {

  if (data.maker.chainId == data.taker.chainIdPool && data.maker.assetId == data.taker.assetId) {
    return 0
  }

  const takerWeb3 = new Web3(NETWORK_URLS[data.taker.chainIdPool]);
  const taker = data.coins[data.taker.coin];
  console.log("taker", taker);
  const takerPoolContract = new takerWeb3.eth.Contract(POOL_ABI, taker.swapAddress)

  const owner = await takerPoolContract.methods.owner().call();
  console.log("takerMeshBalance 1", taker.text);
  const takerMeshBalance = await takerWeb3.eth.getBalance(owner)
  console.log("takerMeshBalance 2", taker.text);

  return makeHumanAmounts(takerMeshBalance, "1000000000000000000")

}

async function getCrosschainFee (data) {

  if (data.maker == null || data.taker == null) {
    return 0
  }

  if (data.maker.chainId == data.taker.chainIdPool && data.maker.assetId == data.taker.assetId) {
    return 0
  }

  const maker = data.coins[data.maker.coin]
  //console.log("maker", maker)


  const makerWeb3 = new Web3(NETWORK_URLS[data.maker.chainId]);
  const makerPoolContract = new makerWeb3.eth.Contract(POOL_ABI, maker.swapAddress)
  const networkFee = await makerPoolContract.methods.chainIdToFee(data.taker.chainIdPool).call();
  
  return Web3.utils.fromWei(networkFee, "ether")

}

async function refreshPoolInfo(state: any, reset: bool) {
  
  const data = state.data;
  const chainId = state.chainId
  const account = state.account;
  //const account = state.account //'0xa45108C2C6d9653f42e389b666d22362c49dC1a8' // state.account
  //const account = '0xf9ae26cf552ebdda6db00ba1cdec8b8ea735e277'
  //alert(account)

  if (account == null)
    return

  if (reset) {
    resetSwapInfo(state)
    data.maker.chainId = chainId
    data.taker.chainIdPool = chainId
    data.taker.assetId = data.maker.assetId
    data.account = account
    
  }

  const account2 = account == null ? '0x3b6343ade9720ad63e72244d4b6d8931d23e97c9' : account
  
  //const supportedCoins = data.coins.filter((c)=> c.chainId == data.maker.chainId)
  //const foraignCoins = data.coins.filter((c)=> c.chainId == data.taker.chainId)

  const supportedMakerCoins = data.coins.filter((c)=> c.chainId == chainId)
  
  if (supportedMakerCoins.length == 0) {
    return
  }

  const makerCoin =  
    data.maker.coin ? data.maker.coin : data.coins.indexOf(supportedMakerCoins[0])
  
  const maker = data.coins[makerCoin];

  if (maker.disabledPool == true) {
    
    //data.page = "Swap"
    return
  }

  //const foraignCoins = data.coins.filter((c)=> c.chainId != chainId && c.group == maker.group)

  if (data.taker.coin == null)
    return
  
  const takerCoin =  data.taker.coin

  const makerWeb3 = new Web3(NETWORK_URLS[maker.chainId])

  //const tokenContract = new makerWeb3.eth.Contract(ERC20_ABI, maker.tokenAddress)
  const makerLPContract = new makerWeb3.eth.Contract(POOL_ABI, maker.swapAddress)
  //const makerPoolContract = new makerWeb3.eth.Contract(Pool_ABI, maker.swapAddress)

  const makerBalanceInfo = await getBalanceInfo(maker, makerWeb3, account2)
  //console.log("makerBalanceInfo", makerBalanceInfo)
  
  const allowanceBN = makerBalanceInfo.allowanceBN;
  
  const makerBalance = makerBalanceInfo.balance;
  
  const makerLPBalance = await makerLPContract.methods.balanceOf(account2).call();
  const makerPoolBalance = makerBalanceInfo.poolBalance;

  const totalLiquidityBN = await makerLPContract.methods.totalLiquidity().call();
  
  const totalSharesBN = await makerLPContract.methods.totalSupply().call();

  const totalLiquidity = makeHumanAmounts(totalLiquidityBN, maker.decimals)

  const totalShares = makeHumanAmounts(totalSharesBN, maker.decimals)
  //console.log("totalShares", totalShares.toString())
  //uint256 what = _share.mul(totalLiquidity.div(10e12)).div(totalShares.div(10e12));
  
  //const taker = data.coins[takerCoin];
  
  //const takerWeb3 = new Web3(NETWORK_URLS[taker.chainId])

  //const takerContract = new takerWeb3.eth.Contract(ERC20_ABI, taker.tokenAddress)
  //const takerPoolContract = new takerWeb3.eth.Contract(POOL_ABI, taker.swapAddress)

  
  //const takerBalance = await takerContract.methods.balanceOf(account2).call();
  //const takerPoolBalance = await takerContract.methods.balanceOf(taker.swapAddress).call();
  
  //console.log("data.taker.chainId", data.taker.chainId)
  //console.log("data.maker.chainId", data.maker.chainId)

  const data_maker_balancePool = makeHumanAmounts(makerPoolBalance, maker.decimals)

  const takerBalancePool = await getTakerBalancePool(data, data_maker_balancePool)
  const takerBalanceEth = await getTakerBalanceEth(data)
  const crosschainFee = await getCrosschainFee(data)
  


  transaction(()=> {
    data.maker.fee.Crosschain = crosschainFee
    data.maker.coin = makerCoin
    data.taker.coin = takerCoin
    data.taker.chainIdPool = data.coins[takerCoin].chainId
    data.taker.assetId = data.coins[takerCoin].assetId
    data.maker.balance = makeHumanAmounts(makerBalance, maker.decimals)
    data.maker.amount = data.maker.balance
    data.maker.balancePool = data_maker_balancePool
    data.maker.balanceLP = makeHumanAmounts(makerLPBalance, maker.decimals) 
    data.maker.totalLiquidity = totalLiquidity
    data.maker.totalShares = totalShares
    data.maker.amountLP = data.maker.balanceLP
    data.maker.allowance = allowanceBN.toString()
    data.taker.balancePool = takerBalancePool
    data.taker.balanceEth = takerBalanceEth
    data.maker.max = +data.maker.balance < +data.taker.balancePool ? data.maker.balance : data.taker.balancePool
  })
}

export default refreshPoolInfo
