import React from 'react'
import styled from 'styled-components'
import renderForm from './renderForm'
import renderConfirmation from './renderConfirmation'
import renderReceive from './renderReceive'
import { Message, MessageIcon, MessageText } from '../PoolForm/Message'
import CloudOff from '../../assets/svg/cloud-off.svg'
//import User from '../../assets/svg/user.svg'
import ConnectWallet from '../../assets/images/connect-your-wallet.png'

const Container = styled.div`
  display: inline-block;
  text-align: left;
  width: 350px;
  padding: 0 10px;
  margin-top: 50px;
  @media (max-width: 500px) {
    width: 100%
  }
`

const StakingForm = (state: any, tokenContract: any, swapContract: any, alert: any) => {
  
  const data = state.data;
  const chainId = state.chainId;
  const account = state.account;
  const maker = data.coins[data.maker.coin];

  if(maker == null) {
    return null
  }

  
  if (data.coins.filter((c)=> c.chainId == chainId).length == 0)
    return (
      <Message>
            <MessageIcon src={CloudOff} alt={'Account'} />
            <MessageText><div>Please connect to a swapz supported network</div></MessageText>
      </Message>)

  if (account == null)
      return (
        <img src={ConnectWallet} style={{width: "250px", opacity: 1}} />)

  if (data.taker.coin == null || data.maker.coin == null) {
    return null
  }
  return (
    <div>
        <Container>
          {renderForm(state, tokenContract, swapContract, alert)}
          {renderConfirmation(state)}
          {renderReceive(state)} 
        </Container>
    </div>
  )
}

export default StakingForm
